import { LoginActionType } from "../LoginType/LoginActionType";

const initialState = {
    ResponseDataLoading:false,
}

export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case LoginActionType.RESPONSE_DATA_LOADING:
            return{
                ...state,
                ResponseDataLoading:true,
            }    
        case LoginActionType.RESPONSE_DATA_SUCCESS:
            return{
                ...state,
                ResponseDataLoading:false,
            }
        default :
            return state;
    }
}