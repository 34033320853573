import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./CustomerOrderHistory.css";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/Navbar";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { CurrentCustomerOrder, get_customer_order_details, menudata } from "../Order/OrderActions/OrderActionApi";
import UserDetails from "../../components/UserDetails";
import { socket_url } from "../../Redux/constants";


const CustomerOrderHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = sessionStorage?.getItem("Token");
  const email = sessionStorage?.getItem("Email");
  const product_id = sessionStorage?.getItem("ProductId");
  const order_id = sessionStorage?.getItem("orderId");
  const tableno = sessionStorage?.getItem("tableno");
  const [ws, setWs] = useState(null);
  const CustomerOrderHistoryResponse = useSelector((state) => state.order.CustomerOrderHistoryResponse);
 
  const [CustomerOrderHistoryData, setCustomerOrderHistoryData] = useState([]);

  useEffect(() => {
    if(!token ||token === "undefined" || token === "null" || token === "none")
      {
          navigate("/customer")
      }
      else
      {
        if(order_id !== "undefined")
        {
          dispatch(get_customer_order_details(product_id, email, order_id, token));
        }
        else
        {
          CustomerOrderId();
        } 
        if(CustomerOrderHistoryResponse.length === 0)
          {
            dispatch(menudata(product_id,email,token));
          }
      }
          // Initialize WebSocket connection
    const socket = new WebSocket(socket_url);
    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      console.log("Raw message:", event.data); // Log the raw message
      try {
        const message = event.data;
        console.log("Parsed message:", message);
      // Handle incoming messages and update state as needed
      if (message === "send_kot" || message === "kot_item_status_update" || message === "transfer_table") {
        dispatch(get_customer_order_details(product_id, email, order_id, token)); // Update KOT history on new message
      }
    }
    catch (error) {
      console.error("Error parsing message:", error);
    }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close(); // Clean up WebSocket connection on unmount
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("orderhistory :", CustomerOrderHistoryResponse.data);
    if (CustomerOrderHistoryResponse && CustomerOrderHistoryResponse.data) {
      setCustomerOrderHistoryData(CustomerOrderHistoryResponse.data);
    }
  }, [CustomerOrderHistoryResponse]);

  const CustomerOrderId = async() =>{
    const Customer_order_id = await dispatch(CurrentCustomerOrder(product_id, email, tableno, token));
    dispatch(get_customer_order_details(product_id, email, Customer_order_id?.current_order_id, token));
  }

  return (
      <Grid container style={{overflowX:"hidden"}}>
        <Grid item xs={12} md={12} lg={2} sx={{zIndex:"9999"}}>
          <Navbar active="customerOrderHistory" />
        </Grid>
      <Grid item xs={12}md={12} lg={10} className="table-container-1">
      <Grid container spacing={0} sx={{paddingRight:"1.5%",paddingLeft:"1.5%"}}>
          <Grid item xs={12} md={12} lg={12}>
              <UserDetails/>
          </Grid>
      </Grid>
      <div>
        <div style={{ display: "flex", alignItems: "center" }} className="customerOrder">
            <p style={{ fontFamily: "Poppins", fontWeight: 300, marginRight: "10px" }}>IN TIME: {CustomerOrderHistoryData?.order_in_time ? CustomerOrderHistoryData?.order_in_time: "---"} </p>
            <p style={{ fontFamily: "Poppins", fontWeight: 300 }}>
                OUT TIME: {CustomerOrderHistoryData?.order_complete_time ? CustomerOrderHistoryData?.order_complete_time : "---"}
            </p>
            </div>

            <Box sx={{ fontSize: "20px", color: "#0093FB" }} className="customerOrder">
                <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>
                <span className="label">ORDER DETAILS: </span>
                <span className="value" style={{ marginRight: "20px" }}>
                    {order_id}
                </span>
                </p>
            </Box>
            <Box sx={{ fontSize: "20px", color: "#0093FB" }} className="customerOrder">
                <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>
                <span className="label">TABLE NUMBER: </span>
                <span className="value" style={{ marginRight: "20px" }}>
                  {CustomerOrderHistoryData.table_id}
                </span>
                </p>
            </Box>
            <table className="Customertable">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {CustomerOrderHistoryData && Object.keys(CustomerOrderHistoryData).length > 0 ? (   
                  Object.entries(CustomerOrderHistoryData?.cart).map(([itemId, cartItem], index) => (
                    <tr key={index}>
                      <td data-label="Item Name">{cartItem[0]}</td>
                      <td data-label="Quantity">{cartItem[1]}</td>
                      <td data-label="Price">{cartItem[2]}</td>
                      <td data-label="Total">{cartItem[1] * cartItem[2]}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="CustomerOrderNotFound">No Order</td>
                  </tr>
                )}
              </tbody>
            </table>
            {CustomerOrderHistoryData && Object.keys(CustomerOrderHistoryData).length > 0 ? (
            <div
                style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "25px",
                fontFamily: "Poppins",
                }}
                className="customerOrder"
            >
                <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>TOTAL</p>
                <p style={{ fontSize: "25px", fontFamily: "Poppins", fontWeight: 600 }}>
                RS {CustomerOrderHistoryData?.grand_total.toLocaleString()}
                </p>
            </div>
            ):(<div></div>)}
            </div>
      </Grid>
    </Grid>
  );
};

export default CustomerOrderHistory;
