import { TYPES } from "../BotHistoryType/BotHistoryType";

const initialState = {
    BotHistoryLoadingData: null,
    BotHistorySuccessData: null,
    BotHistoryResponseData: [],
    BotHistoryFailData: null,

    CompleteBotLoading: null,
    CompleteBotSuccess: null,
    CompleteBotResponse: [],
    CompleteBotFail: null,
}


export default function BotHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.BOT_HISTORY_LODING_DATA_MAIN:
            return {
                ...state,
                BotHistoryLoadingData: true,
                BotHistorySuccessData: false,
                BotHistoryResponseData: [],
                BotHistoryFailData: false,
            };
        case TYPES.BOT_HISTORY_FAIL_DATA_MAIN:
            return {
                ...state,
                BotHistoryLoadingData: false,
                BotHistorySuccessData: false,
                BotHistoryResponseData: [],
                BotHistoryFailData: true,
            };
        case TYPES.BOT_HISTORY_SUCCESS_DATA_MAIN:
            return {
                ...state,
                BotHistoryLoadingData: false,
                BotHistorySuccessData: true,
                BotHistoryResponseData: action.payload,
                BotHistoryFailData: false,
            };
        case TYPES.BOT_HISTORY_CLEAR_DATA_MAIN:
            return {
                ...state,
                BotHistoryLoadingData: null,
                BotHistorySuccessData: null,
                BotHistoryResponseData: [],
                BotHistoryFailData: null,
            };
       
            case TYPES.COMPLETE_BOT_LOADING:
            return {
                ...state,
                CompleteBotLoading: true,
                CompleteBotSuccess: false,
                CompleteBotResponse: [],  
                CompleteBotFail: false,
            };
        case TYPES.COMPLETE_BOT_FAIL:
            return {
                ...state,
                CompleteBotLoading: false,
                CompleteBotSuccess: false,
                CompleteBotResponse: [],
                CompleteBotFail: true,
            };
        case TYPES.COMPLETE_BOT_SUCCESS:
            return {
                ...state,
                CompleteBotLoading: false,
                CompleteBotSuccess: true,
                CompleteBotResponse: action.payload,
                CompleteBotFail: false,
            };
        case TYPES.COMPLETE_BOT_CLEAR:
            return {
                ...state,
                CompleteBotLoading: null,
                CompleteBotSuccess: null,
                CompleteBotResponse: [],
                CompleteBotFail: null,
            };

        default:
            return state;
    }
}


