import { TYPES } from "../KotHistoryType/KotHistoryType";

const initialState = {
    KotHistoryLoadingData: null,
    KotHistorySuccessData: null,
    KotHistoryResponseData: [],
    KotHistoryFailData: null,

    CompleteKotLoading: null,
    CompleteKotSuccess: null,
    CompleteKotResponse: [],
    CompleteKotFail: null,
}


export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.KOT_HISTORY_LODING_DATA_MAIN:
            return {
                ...state,
                KotHistoryLoadingData: true,
                KotHistorySuccessData: false,
                KotHistoryResponseData: [],
                KotHistoryFailData: false,
            };
        case TYPES.KOT_HISTORY_FAIL_DATA_MAIN:
            return {
                ...state,
                KotHistoryLoadingData: false,
                KotHistorySuccessData: false,
                KotHistoryResponseData: [],
                KotHistoryFailData: true,
            };
        case TYPES.KOT_HISTORY_SUCCESS_DATA_MAIN:
            return {
                ...state,
                KotHistoryLoadingData: false,
                KotHistorySuccessData: true,
                KotHistoryResponseData: action.payload,
                KotHistoryFailData: false,
            };
        case TYPES.KOT_HISTORY_CLEAR_DATA_MAIN:
            return {
                ...state,
                KotHistoryLoadingData: null,
                KotHistorySuccessData: null,
                KotHistoryResponseData: [],
                KotHistoryFailData: null,
            };
       
            case TYPES.COMPLETE_KOT_LOADING:
            return {
                ...state,
                CompleteKotLoading: true,
                CompleteKotSuccess: false,
                CompleteKotResponse: [],  
                CompleteKotFail: false,
            };
        case TYPES.COMPLETE_KOT_FAIL:
            return {
                ...state,
                CompleteKotLoading: false,
                CompleteKotSuccess: false,
                CompleteKotResponse: [],
                CompleteKotFail: true,
            };
        case TYPES.COMPLETE_KOT_SUCCESS:
            return {
                ...state,
                CompleteKotLoading: false,
                CompleteKotSuccess: true,
                CompleteKotResponse: action.payload,
                CompleteKotFail: false,
            };
        case TYPES.COMPLETE_KOT_CLEAR:
            return {
                ...state,
                CompleteKotLoading: null,
                CompleteKotSuccess: null,
                CompleteKotResponse: [],
                CompleteKotFail: null,
            };

        default:
            return state;
    }
}


