import React from 'react';
import './MessageUserBox.css';

const MessageUserBox = ({ message, type, onClose }) => {
  return (
    <div className={`message-box ${type}`}>
      <span className="close-button" onClick={onClose}>&times;</span><br/>
      {message}
    </div>
  );
};

export default MessageUserBox;
