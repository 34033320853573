import React, { useEffect, useState, useMemo } from 'react';
import './ElapsedTimeCounterWithEffects.css';

const ElapsedTimeCounter = ({ orderInTime, withEffects }) => {
  const [elapsedTime, setElapsedTime] = useState("00:00:00");

  // Function to parse the target time string and return a Date object
  const parseTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds);
  };

  const orderTime = useMemo(() => parseTime(orderInTime), [orderInTime]);

  // Function to format the time difference
  const formatTimeDifference = (diff) => {
    const totalSeconds = Math.floor(diff / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    let animationFrameId;

    const updateElapsedTime = () => {
      const now = new Date();
      let diff = now - orderTime;

      // Check if the current time is earlier than the order in time
      if (diff < 0) {
        // Adjust the difference to account for the negative time
        diff += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
      }

      setElapsedTime(formatTimeDifference(diff));
      animationFrameId = requestAnimationFrame(updateElapsedTime);
    };

    animationFrameId = requestAnimationFrame(updateElapsedTime);

    // Cleanup on component unmount
    return () => cancelAnimationFrame(animationFrameId);
  }, [orderTime]);

  // Determine the class based on the elapsed time and whether to apply effects
  const getElapsedClass = () => {
    if (withEffects) {
      const [hours, minutes, seconds] = elapsedTime.split(':').map(Number);
      const totalMinutes = hours * 60 + minutes;
      if (totalMinutes >= 30) {
        return 'red';
      } else if (totalMinutes >= 20) {
        return 'orange';
      }
    }
    return '';
  };

  return (
    <span className={`elapsed-time ${getElapsedClass()}`}>
      {elapsedTime}
    </span>
  );
};

export default ElapsedTimeCounter;
