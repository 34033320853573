import { ReserveActionType } from '../ReserveType/ReserveActionType';
import Axios from 'axios';
import { base_url } from "../../../Redux/constants";


export const TableData = (productId,email,token) =>{
    return(dispatch)=>{
        Axios.get(`${base_url}/table_schema?product_id=${productId}&email=${email}`,{
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
            .then((response) =>{
                dispatch({
                    type: ReserveActionType.TABLE_SCHEMA_SUCCESS_DATA_MAIN,
                    payload: response.data
                })
            })
    }
}

export const reservetable= (email,product_id,table_id,members,reserve,current_captain,token) =>{
    const data={
        "email":email,
        "product_id":product_id,
        "table_id":table_id,
        "members":members,
        "reserve":reserve,
        "current_captain":current_captain,
    }
    return(dispatch)=>{
        Axios.post(`${base_url}/reserve_table`,data,{
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
            .then((response) =>{
              dispatch(TableData(product_id,email,token))
                
            })
    }
}

export const CurrentOrder=(productId,email,tableno,token)=>{
  return(dispatch)=>{
    Axios.get(`${base_url}/table_current_order?product_id=${productId}&email=${email}&table_id=${tableno}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then((response) =>{
            dispatch({
                type: ReserveActionType.CURRENT_ORDER_ID,
                payload: response.data
            })
        })
}
}

export const transfertable=(email,product_id,from,to,token) =>{
  const data={
      "email":email,
      "product_id":product_id,
      "change_from_table_id":from,
      "change_to_table_id":to,
  }
  return(dispatch)=>{
      Axios.post(`${base_url}/transfer_table`,data,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
          .then((response) =>{
              
          })
  }
}

export const customer_details=(email,product_id,table_id,customer_name,customer_contact,token) =>{
  const data={
      "email":email,
      "product_id":product_id,
      "table_id":table_id,
      "customer_name":customer_name,
      "customer_contact":customer_contact,
  }
  return(dispatch)=>{
      Axios.post(`${base_url}/add_customer_details`,data,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
          .then((response) =>{
              
          })
  }
}

export const get_customer_details=(productId,email,tableno,token)=>{
  return(dispatch)=>{
    Axios.get(`${base_url}/get_customer_details?product_id=${productId}&email=${email}&table_id=${tableno}`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then((response) =>{
            dispatch({
                type: ReserveActionType.CUSTOMER_DATA,
                payload: response.data
            })
        })
}
}
export const get_order_details=(productId,email,order_id,token)=> async (dispatch) =>{
  try {
    const response = await Axios.get(`${base_url}/get_order_details?product_id=${productId}&email=${email}&order_id=${order_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data; // Return the response object
  } catch (error) {
    throw error; // Rethrow the error to handle it in the component
  }
}

