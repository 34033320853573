import React,{useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import Navbar from '../../components/Navbar';
import {ReactComponent as Twoseater} from '../../assets/2.svg';
import {ReactComponent as Fourseater} from '../../assets/4.svg';
import {ReactComponent as Sixseater} from '../../assets/6.svg';
import {ReactComponent as Eightseater} from '../../assets/8.svg';
import Slide from "@mui/material/Slide";
import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from '@mui/material/Divider';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import loader from '../../assets/EditLoader.gif';

import { useDispatch, useSelector } from 'react-redux';


//import { ReserveActionType } from './ReserveType/ReserveActionType';


import './EditLayout.css';
import { Box, Paper, Typography } from '@mui/material';
import FloorSelect from './FloorSelect';
import UserDetails from '../../components/UserDetails';
import { editLayout } from './EditLayoutActions/EditLayoutApi';
import MessageBox from '../../components/MessageBox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditLayout= () => {
  const dispatch = useDispatch();

  const [openPopup, setOpenPopup] = useState(false);
  const [openFloorPopup, setOpenFloorPopup] = useState(false);
  const [openSeaterPopup, setOpenSeaterPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [Member, setMember] = React.useState(2);
  const [NewFloor, setNewFloor] = React.useState();
  const [NewSeater, setNewSeater] = React.useState();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [status, setStatus] = React.useState();
  const [tableno,settableno] = useState(0);
  const [PopupTableNo,setPopupTableNo] = useState(null);
  const [newTables, setNewTables] = useState({});
  const [TableData,setTableData]= useState({});
  const [RemoveTables, setRemoveTables] = useState({});
  const [RemoveData, setRemoveData] = useState([]);
  const [NewTableData,setNewTableData]= useState([]);
  const [activedelete,setactivedelete] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showMessageBox, setShowMessageBox] = useState(false);

  const [id,setid]=useState(1)

  const product_id = sessionStorage?.getItem("ProductId");
  const email = sessionStorage?.getItem("Email");
  const token = sessionStorage?.getItem("Token");
  const floor =sessionStorage?.getItem("Floor");


  const TableDataMain = useSelector((state)=> state.reservetable.TableSchemaResponseData);
  const TableData1 = useSelector((state) => state.reservetable.TableSchemaResponseDataMain);
  const loadingData = useSelector((state) => state.edit.EditResponseDataLoading);

  useEffect(()=>{
    if(TableData1)
    {
      setTableData(TableData1);
    }
  },[TableDataMain,floor])
  const handleChange = (event) => {
    setMember(event.target.value);
  };
  const handleChange1 = (event) => {
    settableno(event.target.value);
  };


  const handleShowMessage = () => {
    setMessage('Please add the number of seater');
    setMessageType('error');
    setShowMessageBox(true);
  };

  const handleCloseMessageBox = () => {
    setShowMessageBox(false);
  };

  const handleTileClick = (tno) => {
    console.log(tno);
    if(tno!=="")
    {
      setid((prevId) => prevId + 1);
      const highest_table_no = parseInt(TableData1["highest_table_id"])+id;
      const missing_table_no = TableData1["missing_table_ids"];
      setPopupTableNo({ highest: highest_table_no, missing: missing_table_no });
      settableno(highest_table_no)
      setMember(tno[0])
      setPopupData(TableData[floor])
      setOpenPopup(true);
    }
    else{
      handleShowMessage();
    }
    
  };

  const handleFloorPopup =()=>{
    setOpenFloorPopup(true);
  }
  const handleSeaterPopup =()=>{
    setOpenSeaterPopup(true);
  }

  const handleDelete = () => {
    setactivedelete(true);
    if (RemoveTables) {
      setTableData(prevTableData => {
        const updatedTableData = { ...prevTableData };
        Object.keys(RemoveTables).forEach(floor => {
          Object.keys(RemoveTables[floor]).forEach(seater => {
            RemoveTables[floor][seater].forEach(value => {
              if (updatedTableData[floor] && updatedTableData[floor][seater] && updatedTableData[floor][seater][value]) {
                delete updatedTableData[floor][seater][value];
              }
            });
          });
        });
        return updatedTableData;
      });
    }
  };
  

  const handleRemovedata=(seater,newTableNumber,floor)=>{

    if(TableData[floor][seater][newTableNumber] === 'reserved')
    {
      setMessage("Table Can't be deleted as it is reserved");
      setMessageType('error');
      setShowMessageBox(true);
      return; 
    }  

    if (RemoveData.includes(newTableNumber)) {
      // If tile is already highlighted, remove it from the highlightedTiles array
      setRemoveData(prevState => prevState.filter(tile => tile !== newTableNumber));
      setRemoveTables(prevTableData => {
        const updatedTableData = { ...prevTableData };
        if (updatedTableData[floor] && updatedTableData[floor][seater]) {
            updatedTableData[floor][seater] = updatedTableData[floor][seater].filter(value => value !== newTableNumber);
            }
        return updatedTableData;
      });
    } else {
      // If tile is not highlighted, add it to the highlightedTiles array
      setRemoveData(prevState => [...prevState, newTableNumber]);
      const number = newTables?.[floor]?.[seater];
      if (!number || !number?.includes(newTableNumber)) {
        setRemoveTables(prevState => {
          return {
            ...prevState,
            [floor]: {
              ...prevState[floor],
              [seater]: prevState[floor]?.[seater]
                ? [...prevState[floor][seater], newTableNumber]
                : [newTableNumber]
            }
          };
        });
      }
    }
    if (newTables[floor] && newTables[floor][seater]) {
          newTables[floor][seater] = newTables[floor][seater].filter(value => value !== newTableNumber);
    } 
  }
  const handlePopupClose = () => {
    setid(id-1);
    setOpenPopup(false);
    setPopupData(null);
  };
  const handleFloorPopupClose =()=>{
    setOpenFloorPopup(false);
    setError('');
    setInputValue('');
  }
  const handleSeaterPopupClose =()=>{
    setOpenSeaterPopup(false);
    setError('');
    setInputValue('');
  }
  const handlePopuptableClose = () => {
    setOpenPopup(false);
    const floor = sessionStorage?.getItem("Floor");
    if (Member && !isNaN(Member)) {
      const seater = Member;
      const newTableNumber = parseInt(tableno) ; // Increment table number for new tables
      setNewTableData(prevState => [...prevState, newTableNumber]);
      setTableData(prevState => {
        return {
          ...prevState,
          [floor]: {
            ...prevState[floor],
            [seater]: {
              ...prevState[floor]?.[seater],
              [newTableNumber]: 'available'
            }
          }
        };
      });
  
      setNewTables(prevState => {
        return {
          ...prevState,
          [floor]: {
            ...prevState[floor],
            [seater]: prevState[floor]?.[seater]
              ? [...prevState[floor][seater], newTableNumber]
              : [newTableNumber]
          }
        };
      });
  
    }
    setPopupData(null);
  };

  const handleSavedata=()=>{
    if(activedelete)
    {
      dispatch(editLayout(newTables,RemoveTables,email,product_id,token));
      setactivedelete(false);
      setNewTables({});
      setRemoveTables({});
      setRemoveData([]);
      setNewTableData([]);
      setNewFloor();
      setNewSeater();
      setid(1);
    }
    else
    {
      dispatch(editLayout(newTables,{},email,product_id,token));
      setNewTables({});
      setNewTableData([]);
      setNewFloor();
      setNewSeater();
      setid(1);
    }
    
  }

  const handleInputChange = (event) => {
    const floorno = event.target.value+"F";
    setInputValue(event.target.value);
    if (Object.keys(TableData).includes(floorno)) {
      setError('Floor already exists');
      return;
    }
    else{
      setError('');
      return;
    }
  };

  const handleAddFloor = () => {
    if (inputValue.trim() === '') {
      setError('Floor cannot be empty');
      return;
    }
    if (Object.keys(TableData).includes(inputValue+"F")) {
      setError('Floor already exists');
      return;
    }
    const floor=inputValue+"F";
    setNewFloor(floor)
    setInputValue('');
    setError('');
    setOpenFloorPopup(false);
  };

  const handleSeaterChange = (event) => {
    const Seaterno = event.target.value;
    setInputValue(event.target.value);
    if(TableData?.[floor])
    {
      if (Object?.keys(TableData?.[floor]).includes(Seaterno)) {
        setError('Seater number already exists');
        return;
      }
      else{
        setError('');
        return;
      }
    }
  };

  const handleAddSeater = () => {
    if (inputValue.trim() === '') {
      setError('Seater Number cannot be empty');
      return;
    }
    if(TableData?.[floor])
    {
      if (Object.keys(TableData?.[floor]).includes(inputValue)) {
        setError('Seater Number already exists');
        return;
      }
    }
    setTableData(prevState => {
      return {
        ...prevState,
        [floor]: {
          ...prevState[floor],
          [inputValue]: {
           
          }
        }
      };
    });
    setInputValue('');
    setError('');
    setOpenSeaterPopup(false);
  };

  return (
    <>
    <Grid container spacing={1} sx={{filter: loadingData ? "blur(5px)" : "none",pointerEvents: loadingData ? "none" : "auto"}}>
      {loadingData && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <img src={loader} alt="Loader" />
        </div>
      )}
            <Grid item xs={12} md={3} lg={2}>
              <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                <Navbar active="editLayout" />
              </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"auto"}} className='container-main'>
              <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                <Grid item xs={12} md={12} lg={12} sx={{zIndex:"1"}}>
                    <UserDetails/>
                </Grid>
              </Grid>
              <Paper elevation={3} sx={{height:"min-content",marginRight:"1.5%",borderRadius:7}} className='page'>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={4} md={4} lg={4} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent: "flex-start"}}>
                            <FloorSelect data={NewFloor}/>
                        </Grid>
                        <Grid item xs={8} md={8} lg={8} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent: "flex-end",paddingRight:"2.5%"}}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        borderRadius: 5, // Adjust the border radius as needed
                                        fontFamily: "Poppins", // Set the font family
                                        marginRight:"1.5%",
                                        backgroundColor: "#0093FB",
                                        textAlign: "center" ,
                                    }}
                                    onClick={() => handleFloorPopup()}
                                    className='tables1'
                                    >
                                    Add Floor
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        borderRadius: 5, // Adjust the border radius as needed
                                        fontFamily: "Poppins", // Set the font family
                                        marginRight:"1.5%",
                                        backgroundColor: "#0093FB",
                                        textAlign: "center" ,
                                    }}
                                    onClick={() => handleSeaterPopup()}
                                    className='tables1'
                                    >
                                    Add Seater
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        borderRadius: 5, // Adjust the border radius as needed
                                        fontFamily: "Poppins", // Set the font family
                                        marginRight:"1.5%",
                                        backgroundColor: "#8E0515",
                                        textAlign: "center" ,
                                    }}
                                    onClick={() => handleDelete()}
                                    className='tables1'
                                    >
                                    Delete Table
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        borderRadius: 5, // Adjust the border radius as needed
                                        fontFamily: "Poppins", // Set the font family
                                        marginRight:"1.5%",
                                        backgroundColor: "#058E6E",
                                        textAlign: "center" ,
                                    }}
                                    onClick={() => handleTileClick(TableData?.[floor] ? Object?.keys(TableData?.[floor]):"")}
                                    className='tables1'
                                    >
                                    Add Table
                                </Button>
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{marginTop:"0.5%",overflowX:"auto",paddingRight:"1%",display: 'flex',alignItems: 'center'}}>
                  {TableData?.[floor] && Object?.keys(TableData?.[floor])?.map((item)=>(
                      <Grid item xs={6} md={3} lg={3}>
                      <Box sx={{
                       backgroundColor:"#E7E7E7",
                       borderRadius: 7,
                       marginLeft: "5%",
                       marginBottom: "5%",
                      }}
                      className="table-data">
                         <Box sx={{
                           backgroundColor:"#0093FB",
                           borderTopRightRadius: 20,
                           borderTopLeftRadius: 20,
                           height:"10%",
                           display: "flex", // Ensures flexbox layout
                           alignItems: "center", // Centers items vertically
                           justifyContent: "center", // Centers items horizontally
                           textAlign: 'center',
                         }}>
                             <Typography sx={{
                                       fontFamily: 'Poppins',
                                       fontWeight: '600',
                                       color: '#ffff',
                                       textAlign: 'center',
                                       }}
                                       noWrap
                                       component="div"
                                       className='seater'
                              >
                               {item} Seater
                             </Typography>
                         </Box>
                         <Box sx={{
                           marginTop: "5%",
                           height: "15%",
                           textAlign: 'center',
                           marginBottom: '5%',
                         }}>
                          {Number(item) === 2 ? <Twoseater className='s2'/> : 
                            Number(item) === 4 ? <Fourseater className='s2'/> : 
                            Number(item) === 6 ? <Sixseater  className='s6'/> : 
                            <Eightseater className='s6'/>}   
                         </Box>
                         <Divider sx={{
                           width: "50%",
                           textAlign: "center",
                           borderBottom: "4px solid #B0AFAF",
                           margin: "auto",
                         }}/>
                         <Grid container spacing={1} sx={{marginTop:"1.5%",overflowY:"auto",justifyContent: "center",alignItems: "center",paddingBottom:"5%"}}>
                          {Object?.keys(TableData?.[floor]?.[item])?.map((value)=>(
                            <Grid item xs={5} md={5} lg={5}>
                              <div
                                key={value}
                                className="tile"
                                onClick={() => handleRemovedata(item,value,floor)}
                                style={{
                                  backgroundColor:RemoveData.includes(value) ? '#8E0515' : NewTableData.includes(parseInt(value)) ? '#39B498': '#D9D9D9',
                                  color: RemoveData.includes(value) ? '#FFFFFF' : NewTableData.includes(parseInt(value)) ? '#FFFFFF' : '#7D7D7D',
                                  border: RemoveData.includes(value) ? '1px solid #8E0515' : NewTableData.includes(parseInt(value)) ? '1px solid #39B498' : '1px solid #000000',
                                  
                                }}
                              >
                                {value}
                              </div>
                            </Grid>
                          ))}
                         </Grid>
                      </Box>
                   </Grid>
                  ))}
                </Grid>
                <Grid container spacing={1} sx={{paddingBottom:"1%"}}>
                    <Grid item xs={12} md={12} lg={12} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent:"center"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    borderRadius: 5, // Adjust the border radius as needed
                                    fontFamily: "Poppins", // Set the font family
                                    fontSize: "18px", // Adjust the font size as needed
                                    marginRight:"1.5%",
                                    backgroundColor: "#0093FB",
                                    textAlign: "center" ,
                                    width:"10rem"// Set the background color
                                }}
                                onClick={()=>handleSavedata()}
                            >
                                Save
                            </Button>
                    </Grid>
                </Grid>
                      <Dialog
                  open={openPopup}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "20rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                  {popupData && (
                    <DialogContent className="dialog-content">
                      {popupData && (
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Table No</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={tableno}
                                onChange={handleChange1}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"30%"}}
                              >
                                {PopupTableNo?.missing.concat(PopupTableNo?.highest)?.map((item)=>{
                                  console.log(PopupTableNo)
                                  return (item>0) ? (
                                    <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}} key={item}>
                                        {item}
                                    </MenuItem>
                                ) : null;})}
                              </Select>
                            </FormControl>
                          </Box>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">No. of Seats</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={Member}
                                onChange={handleChange}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"30%"}}
                              >
                                {Object?.keys(popupData)?.map((item)=>(
                                  <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                    </DialogContent>
                  )}
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"50%"// Set the background color
                      }}
                      onClick={()=>handlePopuptableClose()}
                    >
                      Add Table
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openFloorPopup}
                  onClose={handleFloorPopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "16rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handleFloorPopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                    <DialogContent className="dialog-content">
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Floor No</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'20%'}}>
                            <TextField
                              value={inputValue}
                              onChange={handleInputChange}
                              error={!!error}
                              helperText={error}
                            />
                          </Box>
                        </div>
                    </DialogContent>
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"50%"// Set the background color
                      }}
                      onClick={()=>handleAddFloor()}
                    >
                      Add Floor
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openSeaterPopup}
                  onClose={handleSeaterPopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "16rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handleSeaterPopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                    <DialogContent className="dialog-content">
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Number of Seater</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'20%'}}>
                            <TextField
                              value={inputValue}
                              onChange={handleSeaterChange}
                              error={!!error}
                              helperText={error}
                            />
                          </Box>
                        </div>
                    </DialogContent>
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"50%"// Set the background color
                      }}
                      onClick={()=>handleAddSeater()}
                    >
                      Add Seater
                    </Button>
                  </DialogActions>
                </Dialog>
                {showMessageBox && (
                  <MessageBox
                    message={message}
                    type={messageType}
                    duration={3000}
                    onClose={handleCloseMessageBox}
                  />
                )}
              </Paper>
            </Grid>
    </Grid>
    </>
  );
};

export default EditLayout;