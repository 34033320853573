import Axios from 'axios';
import { base_url } from "../../../Redux/constants";
import { EditActionType } from '../EditLayoutType/EditActionType';


export const editLayout=(addtable,removetable,email,product_id,token)=>{

    const data={
        "email":email,	
        "product_id":product_id,
        "add":addtable,
        "remove":removetable,
    }
    return(dispatch)=>{
        dispatch({
            type: EditActionType.TABLE_SCHEMA_LODING_UPDATED_DATA,
        })
        Axios.post(`${base_url}/edit_table_schema`,data,{
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
          .then((response) =>{
            dispatch({
                type: EditActionType.TABLE_SCHEMA_SUCCESS_UPDATED_DATA,
            })
        })
        .catch((error) => {
            console.error("Error fetching menu data:", error);
        });
        }
    

}

//