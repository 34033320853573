import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import Navbar from "../../../components/Navbar";
import UserDetails from "../../../components/UserDetails";
import { ReactComponent as Edit } from '../../../assets/Edit.svg';
import { ReactComponent as Product } from '../../../assets/Product.svg';
import { ReactComponent as Add } from '../../../assets/Addproperties.svg';
import { ReactComponent as AddProduct } from '../../../assets/Addproduct.svg';
import { ReactComponent as EditProduct } from '../../../assets/EditProduct.svg';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get_product_data } from "./ManageActions/ManageActions";

const Manage = () =>{
    const token = sessionStorage?.getItem("Token");
    const email = sessionStorage?.getItem("Email");
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const navigate = useNavigate();

    const opennewpage = () =>{
        sessionStorage.setItem('product','Add')
        navigate('/addproduct')
    }
    const opennewpageEdit = async() =>{
        await dispatch(get_product_data(email,token))
        sessionStorage.setItem('product','Edit')
        navigate('/editproduct')
    }
    const opennewpageManage = async() =>{
        await dispatch(get_product_data(email,token))
        sessionStorage.setItem('product','Manage')
        navigate('/manageproduct')
    }
    
    return(
    <>
        <Grid container spacing={1} sx={{/* filter: loadingData ? "blur(5px)" : "none",pointerEvents: loadingData ? "none" : "auto", */height:"100vh"}}>
           {/*  {loadingData && (
                <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                }}
                >
                <img src={loader} alt="Loader" />
                </div>
            )} */}
            <Grid item xs={12} md={3} lg={2}>
                <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                    <Navbar active="manage" />
                </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"99vh"}} className='container-main'>
                <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserDetails/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={11.7} sx={{marginTop:"1.5%",height:"25%"}}>
                    <Paper elevation={3} 
                            sx={{
                                height:'100%',
                                '&:hover': {
                                    backgroundColor:'#0093FB',
                                    color: '#FFFFF',
                                    cursor: 'pointer' // Change this to your desired hover color
                                        }
                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={()=>{
                                    opennewpage()
                                }}
                    >
                        <Grid item xs={12}sx={{height:'100%'}}>
                            <Grid container spacing={1} sx={{padding:2,height:'100%'}}>
                                <Grid item xs={5} sx={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                {isHovered ? <Add /> : <AddProduct />}
                                </Grid>
                                <Grid item xs={7}sx={{display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '800',
                                        color: isHovered ? '#FFFFFF' : '#2E2E2E',
                                        paddingLeft:"5.5%",
                                    }}
                                        variant="h3"
                                        noWrap
                                        component="div"
                                >
                                    Add Product
                                </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={11.7} sx={{marginTop:"2.5%",height:"25%"}}>
                    <Paper elevation={3} 
                        sx={{
                            '&:hover': {
                                    backgroundColor:'#0093FB',
                                    color: '#FFFFF', // Change this to your desired hover color
                                    cursor: 'pointer'
                                        },
                            height:'100%',
                            }}
                            onMouseEnter={() => setIsHovered1(true)}
                            onMouseLeave={() => setIsHovered1(false)}
                            onClick={()=>{
                                opennewpageEdit()
                            }}
                    >
                        <Grid item xs={12}sx={{height:'100%'}}>
                            <Grid container spacing={1} sx={{padding:2,height:'100%'}}>
                                <Grid item xs={5} sx={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                {isHovered1 ? <EditProduct /> : <Edit />}
                                </Grid>
                                <Grid item xs={7}sx={{display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '800',
                                        color: isHovered1 ? '#FFFFFF' : '#2E2E2E',
                                        paddingLeft:"5.5%",
                                    }}
                                        variant="h3"
                                        noWrap
                                        component="div"
                                >
                                    Edit Product
                                </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={11.7} sx={{marginTop:"2.5%",height:"25%"}}>
                    <Paper elevation={3} 
                        sx={{
                            height:'100%',
                            '&:hover': {
                                    backgroundColor:'#0093FB',
                                    color: '#FFFFF', // Change this to your desired hover color
                                    cursor: 'pointer'
                                        }
                            }}
                            onMouseEnter={() => setIsHovered2(true)}
                            onMouseLeave={() => setIsHovered2(false)}
                            onClick={()=>opennewpageManage()}
                    >
                        <Grid item xs={12}sx={{height:'100%'}}>
                            <Grid container spacing={1} sx={{padding:2,height:'100%'}}>
                                <Grid item xs={5} sx={{display:'flex',alignItems:'center',justifyContent:'flex-end',height:'100%'}}>
                                {isHovered2 ? <Add /> : <Product/>}
                                </Grid>
                                <Grid item xs={7}sx={{display:'flex',alignItems:'center',justifyContent:'flex-start',height:'100%'}}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Poppins',
                                        fontWeight: '800',
                                        color: isHovered2 ? '#FFFFFF' : '#2E2E2E',
                                        paddingLeft:"5.5%",
                                    }}
                                        variant="h3"
                                        noWrap
                                        component="div"
                                >
                                    Manage Product
                                </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}
export default Manage;