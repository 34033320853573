import { ReserveActionType } from "../ReserveType/ReserveActionType";

const initialState = {
    TableSchemaSuccessData: null,
    TableSchemaLoadingData: null,
    TableSchemaResponseData: [],
    TableSchemaFailData: null,

    TableSchemaSuccessDataMain: null,
    TableSchemaLoadingDataMain: null,
    TableSchemaResponseDataMain: [],
    TableSchemaFailDataMain: null,

    CurrentOrderId:[],

    CustomerData:[],

};

export default function ReserveReducer(state = initialState, action) {
    switch (action.type) {
        case ReserveActionType.TABLE_SCHEMA_LODING_DATA:
            return{
                ...state,
                TableSchemaSuccessData: false,
                TableSchemaLoadingData: true,
                TableSchemaResponseData: [],
                TableSchemaFailData: false,
            }
        case ReserveActionType.TABLE_SCHEMA_SUCCESS_DATA:
            return{
                ...state,
                TableSchemaSuccessData: true,
                TableSchemaLoadingData: false,
                TableSchemaResponseData: action.payload,
                TableSchemaFailData: false,
            }
        case ReserveActionType.TABLE_SCHEMA_FAIL_DATA:
            return{
                ...state,
                TableSchemaSuccessData: false,
                TableSchemaLoadingData: false,
                TableSchemaResponseData: [],
                TableSchemaFailData: true,
            }
        case ReserveActionType.TABLE_SCHEMA_CLEAR_DATA:
            return{
                ...state,
                TableSchemaSuccessData: null,
                TableSchemaLoadingData: null,
                TableSchemaResponseData: [],
                TableSchemaFailData: null,
            }
        case ReserveActionType.TABLE_SCHEMA_LODING_DATA_MAIN:
            return{
                ...state,
                TableSchemaSuccessDataMain: false,
                TableSchemaLoadingDataMain: true,
                TableSchemaResponseDataMain: [],
                TableSchemaFailDataMain: false,
            }
        case ReserveActionType.TABLE_SCHEMA_SUCCESS_DATA_MAIN:
            return{
                ...state,
                TableSchemaSuccessDataMain: true,
                TableSchemaLoadingDataMain: false,
                TableSchemaResponseDataMain: action.payload,
                TableSchemaFailDataMain: false,
            }
        case ReserveActionType.TABLE_SCHEMA_FAIL_DATA_MAIN:
            return{
                ...state,
                TableSchemaSuccessDataMain: false,
                TableSchemaLoadingDataMain: false,
                TableSchemaResponseDataMain: [],
                TableSchemaFailDataMain: true,
            }
        case ReserveActionType.TABLE_SCHEMA_CLEAR_DATA_MAIN:
            return{
                ...state,
                TableSchemaSuccessDataMain: null,
                TableSchemaLoadingDataMain: null,
                TableSchemaResponseDataMain: [],
                TableSchemaFailDataMain: null,
            }
        case ReserveActionType.CURRENT_ORDER_ID:
            return{
                ...state,
                CurrentOrderId: action.payload,
            }
        case ReserveActionType.CUSTOMER_DATA:
            return{
                ...state,
                CustomerData: action.payload,
            }
        default:
            return state;

    }

}