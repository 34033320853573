import React,{useEffect,useState} from 'react';
import Grid from '@mui/material/Grid';
import Navbar from '../../components/Navbar';
import {ReactComponent as Twoseater} from '../../assets/2.svg';
import {ReactComponent as Fourseater} from '../../assets/4.svg';
import {ReactComponent as Sixseater} from '../../assets/6.svg';
import {ReactComponent as Eightseater} from '../../assets/8.svg';
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from '@mui/material/Divider';
import { IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { useDispatch, useSelector } from 'react-redux';


import './Reserve.css';
import { Box, Paper, Typography } from '@mui/material';
import FloorSelect from './FloorSelect';
import { CurrentOrder, customer_details, get_customer_details, get_order_details, reservetable, transfertable } from './ReserveActions/ReserveActionApi';
import { useNavigate } from 'react-router-dom';
import UserDetails from '../../components/UserDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Reserve= () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [openPopupOrderDetails, setOpenPopupOrderDetails] = useState(false);
  const [openCustomerDetailsPopup,setCustomerDetailsPopup]=useState(false);
  const [popupData, setPopupData] = useState(null);
  const [popupOrderData,setPopupOrderData] = useState(null);
  const [Member, setMember] = React.useState();
  const [Value, setValue] = React.useState();
  const [status, setStatus] = React.useState();
  const username = sessionStorage?.getItem("Username");
  const product_id = sessionStorage?.getItem("ProductId");
  const email = sessionStorage?.getItem("Email");
  const token = sessionStorage?.getItem("Token");
  const floor =sessionStorage?.getItem("Floor");
  const [OrderId,setOrderId] = useState('');
  const [TransferFloor,setTransferFloor] = useState('');
  const [TransferTable,setTransferTable] = useState('');
  const [CustomerName,setCustomerName]=useState('');
  const [CustomerNumber,setCustomerNumber]=useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const TableData = useSelector((state)=> state.reservetable.TableSchemaResponseData);
  const TableData1 = useSelector((state) => state.reservetable.TableSchemaResponseDataMain);
  const OrderNumber = useSelector((state)=>state.reservetable.CurrentOrderId);
  const CustomerData = useSelector((state)=>state.reservetable.CustomerData)

  const handleChange = (event) => {
    setMember(event.target.value);
  };

  const handleTransferFloor = (event)=>{
    setTransferFloor(event.target.value)
  }

  const handleTransferTable = (event) =>{
    setTransferTable(event.target.value)
  }

  useEffect(()=>{
    setOrderId(OrderNumber.current_order_id)
  },[OrderNumber])

  const handlePopupTransfer=()=>{
    setOpenPopup1(false);
    setOpenPopup2(true);
    setTransferFloor(floor)
  }

  const handleCustomerDetailPopup =()=>{
    setOpenPopup1(false);
    setCustomerDetailsPopup(true);
  }

  const handleTileClick = async (number,value,status) => {
    const items = Array.from({ length: number }, (_, index) => `${index + 1}`);
    setPopupData(items)
    setValue(value)
    setMember(number)
    if(status === 'available')
    {
        setOpenPopup(true);
        setStatus('book');
    }
    else if(status === 'reserved')
    {
      await dispatch(CurrentOrder(product_id,email,value,token));
      setOpenPopup1(true);
      setOrderId('');
      setStatus('unbook');
    }
    dispatch(get_customer_details(product_id,email,value,token));
  };
  const handleOrderDetails= async()=>{
    if(OrderId !== 'None')
    {
      const data = await dispatch(get_order_details(product_id,email,OrderId,token));
      setPopupOrderData(data['data'])
    }
    setOpenPopup1(false);
    setOpenPopupOrderDetails(true);
  }
  const handlePopupClose = () => {
    setOpenPopup(false);
    setOpenPopup1(false);
    setOpenPopup2(false);
    setCustomerDetailsPopup(false);
    setOpenPopupOrderDetails(false);
    setPopupData(null);
  };

  const handlePopupReserveClose = async() => {
    await dispatch(reservetable(email,product_id,Value,Member,status,username,token))
    setOpenPopup(false);
    setOpenPopup1(false);
    setPopupData(null);
  };

  const handlePopupOrderClose = () => {
    if(CustomerData["customer_name"] === null)
    {
      handleCustomerDetailPopup();
      return;
    }
    sessionStorage.setItem("tableno",Value)
    sessionStorage.setItem("orderId",OrderId)
    navigate(`/Order`);
    setOpenPopup(false);
    setOpenPopup1(false);
    setPopupData(null);
  };

  const handlePopupTransferClose =()=>{
    if(TransferTable!== '')
    {
      dispatch(transfertable(email,product_id,Value,TransferTable,token))
      setTransferTable('');
      setOpenPopup2(false);
    }
  }

  const handleCustomerName=(event)=>{
      setCustomerName(event.target.value)
  }
  
  const handleCustomerNumber=(event)=>{
    setCustomerNumber(event.target.value)
    
  }

  const validateCustomerNumber = (number) => {
    const numberRegex = /^\d{10}$/;
    if (numberRegex.test(number)) {
      setErrorMessage('');
      return true;
    } else {
      if (!/^\d*$/.test(number)) {
        setErrorMessage('Customer number must contain only digits.');
      } else if (number.length !== 10) {
        setErrorMessage('Customer number must be exactly 10 digits.');
      }
      return false;
    }
  };

  const submitCustomerDetail= async()=>{
    if(CustomerName !== '' && CustomerNumber !== '')
    {
      if(validateCustomerNumber(CustomerNumber))
      {
        await dispatch(customer_details(email,product_id,Value,CustomerName,CustomerNumber,token))
        sessionStorage.setItem("tableno",Value)
        sessionStorage.setItem("orderId",OrderId)
        setCustomerDetailsPopup(false)
        setOpenPopup(false);
        setOpenPopup1(false);
        setPopupData(null);
        setCustomerName('');
        setCustomerNumber('');
        navigate(`/Order`);
      }
    } 
    
  }

  return (
    <>
    <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2}>
              <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                <Navbar active="reserve" />
              </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"auto"}} className='container-main'>
              <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                <Grid item xs={12} md={12} lg={12}>
                    <UserDetails/>
                </Grid>
              </Grid>
              <Paper elevation={3} sx={{height:"auto",marginRight:"1.5%",borderRadius:7}} className='page'>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12} sx={{display:"flex",flexWrap:"wrap",alignItems:"center",justifyContent: "flex-end"}}>
                    <Typography
                      sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            color: '#5E5E5E',
                            textAlign:'center',
                          }}
                            component="div"
                      >
                      Select Floor :
                      </Typography>
                      <FloorSelect/>
                  </Grid>
                </Grid>
                <Grid container spacing={1} sx={{marginTop:"0.5%",overflowX:"auto",paddingRight:"1%"}}>
                  {TableData && Object?.keys(TableData)?.map((item)=>(
                      <Grid item xs={6} md={3} lg={3}>
                      <Box sx={{
                       backgroundColor:"#E7E7E7",
                       borderRadius: 7,
                       marginLeft: "5%",
                       marginBottom: "5%",
                      }}
                      className="table-data">
                         <Box sx={{
                           backgroundColor:"#0093FB",
                           borderTopRightRadius: 20,
                           borderTopLeftRadius: 20,
                           height:"10%",
                           display: "flex", // Ensures flexbox layout
                           alignItems: "center", // Centers items vertically
                           justifyContent: "center", // Centers items horizontally
                           textAlign: 'center',
                         }}>
                             <Typography sx={{
                                       fontFamily: 'Poppins',
                                       fontWeight: '600',
                                       color: '#ffff',
                                       textAlign: 'center',
                                       }}
                                       noWrap
                                       component="div"
                                       className='seater'
                              >
                               {item} Seater
                             </Typography>
                         </Box>
                         <Box sx={{
                           marginTop: "5%",
                           height: "15%",
                           textAlign: 'center',
                           marginBottom: '5%',
                         }}>
                          {Number(item) === 2 ? <Twoseater className='s2'/> : 
                            Number(item) === 4 ? <Fourseater className='s2'/> : 
                            Number(item) === 6 ? <Sixseater  className='s6'/> : 
                            <Eightseater className='s6'/>}   
                         </Box>
                         <Divider sx={{
                           width: "50%",
                           textAlign: "center",
                           borderBottom: "4px solid #B0AFAF",
                           margin: "auto",
                         }}/>
                         <Grid container spacing={1} sx={{marginTop:"1.5%",overflowY:"auto",justifyContent: "center",alignItems: "center",paddingBottom:"5%"}}>
                          {Object?.keys(TableData[item])?.map((value)=>(
                            <Grid item xs={5} md={5} lg={5}>
                              <div
                                key={value}
                                className="tile"
                                onClick={() => handleTileClick(item,value,TableData[item][value])}
                                style={{
                                  backgroundColor: TableData[item][value] === 'available' ? '#D9D9D9' : TableData[item][value] === 'reserved'? '#058E6E':"#0AAE8E",
                                  color: TableData[item][value] === 'available' ? '#7D7D7D' : '#FFFFFF',
                                  border: TableData[item][value] === 'available' ? '1px solid #000000': TableData[item][value] === 'reserved'? '1px solid #058E6E': '1px solid #0AAE8E',
                                  borderRadius: 7,
                                }}
                              >
                                {value}
                              </div>
                            </Grid>
                          ))}
                         </Grid>
                      </Box>
                   </Grid>
                  ))}
                  
                </Grid>
                      <Dialog
                  open={openPopup}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "14rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                  {popupData && (
                    <DialogContent className="dialog-content">
                      {popupData && (
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">No. of Members</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={Member}
                                onChange={handleChange}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"30%"}}
                              >
                                {popupData.map((item)=>(
                                  <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                    </DialogContent>
                  )}
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={handlePopupReserveClose}
                    >
                      Reserve
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openPopup1}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "500px", // Adjust the width as needed to make it square
                      height: "auto", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>
                    <DialogContent className="dialog-content-2">
                        <div>
                          <Box sx={{ fontSize: "30px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:500 }}>
                              <span className="label">What you want to do?</span>
                            </p>
                          </Box>
                        </div>
                    </DialogContent>
                    <Grid xs={12} sx={{paddingLeft:'5%',paddingRight:'5%'}}>
                      <Grid container spacing={1}>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions" style={{ justifyContent: 'center'}}>
                            <Button
                              variant="contained"
                              className="button-hover"
                              style={{
                                borderRadius: "12px", // Adjust the border radius as needed
                                fontFamily: "Poppins", // Set the font family
                                fontSize: "18px", // Adjust the font size as needed
                                backgroundColor: "rgba(0, 0, 0, 0.59)",
                                color: "#ffffff", // Set the text color
                                width: "90%" ,// Set the width
                                height: '4.6rem',
                              }}
                              onClick={handlePopupOrderClose}
                            >
                              Order
                            </Button>
                          </DialogActions>
                        </Grid>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions" style={{ justifyContent: 'center'}}>
                            <Button
                              variant="contained"
                              className="button-hover"
                              style={{
                                borderRadius: "12px", // Adjust the border radius as needed
                                fontFamily: "Poppins", // Set the font family
                                fontSize: "18px", // Adjust the font size as needed
                                backgroundColor: "rgba(0, 0, 0, 0.59)",
                                color: "#ffffff", // Set the text color
                                width: "90%" ,// Set the width
                                height: '4.6rem',
                              }}
                              onClick={handleOrderDetails}
                            >
                              Order Details
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                className="button-hover"
                                style={{
                                  borderRadius: "12px", // Adjust the border radius as needed
                                  fontFamily: "Poppins", // Set the font family
                                  fontSize: "18px", // Adjust the font size as needed
                                  backgroundColor: "rgba(0, 0, 0, 0.59)",
                                  color: "#ffffff", // Set the text color
                                  width: "90%",// Set the width
                                  height: '4.6rem',
                                }}
                                onClick={handlePopupTransfer}
                              >
                                Transfer Table
                              </Button>
                          </DialogActions>
                        </Grid>
                        <Grid xs={12} md={6} lg={6}>
                          <DialogActions className="dialog-actions-bottom" style={{ justifyContent: 'center' }}>
                            <Button
                              variant="contained"
                              className="button-hover"
                              style={{
                                borderRadius: "12px", // Adjust the border radius as needed
                                fontFamily: "Poppins", // Set the font family
                                fontSize: "18px", // Adjust the font size as needed
                                backgroundColor: "rgba(0, 0, 0, 0.59)",
                                color: "#ffffff", // Set the text color
                                width: "90%" ,// Set the width
                                height: '4.6rem',
                              }}
                              onClick={handlePopupReserveClose}
                            >
                              UnReserve
                            </Button>
                          </DialogActions>
                        </Grid>
                      </Grid>
                    </Grid>
                </Dialog>

                <Dialog
                  open={openPopup2}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "20rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                  {TableData1 && (
                    <DialogContent className="dialog-content">
                      {TableData1&& (
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Floor No.</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={TransferFloor}
                                onChange={handleTransferFloor}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"32%"}}
                              >
                                {Object?.keys(TableData1).map((item)=>{
                                  return (item !== "highest_table_id" && item !== "missing_table_ids") ?(
                                  <MenuItem value={item} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>{item}</MenuItem>
                                ):null})}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                      {TableData1?.[TransferFloor]&& (
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Table No.</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'40%'}}>
                            <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                              <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={TransferTable}
                                onChange={handleTransferTable}
                                sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"32%"}}
                              >
                               {Object?.keys(TableData1?.[TransferFloor]).map((item) =>
                                  Object?.keys(TableData1?.[TransferFloor]?.[item]).map((value) =>{
                                    return(TableData1?.[TransferFloor]?.[item]?.[value] === 'available') ? (
                                      <MenuItem key={value} value={value} sx={{ color: '#5E5E5E', fontSize: '1rem', fontFamily: 'poppins' }}>{value}</MenuItem>
                                    ) : null}
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      )}
                    </DialogContent>
                  )}
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center'}}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={handlePopupTransferClose}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openCustomerDetailsPopup}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "25rem", // Adjust the width as needed to make it square
                      height: "19.5rem", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                    <DialogContent className="dialog-content">
                        <div>
                          <Box sx={{ fontSize: "20px", color: "#5E5E5E", textAlign: "center" }}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">Customer Details</span>
                            </p>
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'20%'}}>
                            <TextField
                              placeholder='Name'
                              value={CustomerName}
                              onChange={handleCustomerName}
                              //error={!!error}
                              //helperText={error}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                },
                              }}
                            />
                          </Box>
                          <Box sx={{ minWidth: '50%',marginLeft:'20%',marginTop:"5%"}}>
                            <TextField
                              placeholder='Phone no.'
                              value={CustomerNumber}
                              onChange={handleCustomerNumber}
                              error={!!errorMessage}
                              helperText={errorMessage}
                              type='number'
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                },
                              }}
                            />
                          </Box>
                        </div>
                    </DialogContent>
                  <DialogActions className="dialog-actions" style={{ justifyContent: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"50%"// Set the background color
                      }}
                      onClick={()=>submitCustomerDetail()}
                    >
                      Save Details
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
            open={openPopupOrderDetails}
            onClose={handlePopupClose}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <div style={{ position: "relative" }}>
                <IconButton
                  aria-label="close"
                  onClick={handlePopupClose}
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>

              <DialogContent className="dialog-content">
                  <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
  <p style={{ fontFamily: "Poppins", fontWeight: 300, marginRight: "10px" }}>IN TIME: {popupOrderData?.order_in_time ? popupOrderData?.order_in_time: "---"} </p>
  <p style={{ fontFamily: "Poppins", fontWeight: 300 }}>
    OUT TIME: {popupOrderData?.order_complete_time ? popupOrderData?.order_complete_time : "---"}
  </p>
</div>

                    <Box sx={{ fontSize: "25px", color: "#0093FB" }}>
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>
                        <span className="label">ORDER DETAILS: </span>
                        <span className="value" style={{ marginRight: "20px" }}>
                          {OrderId}
                        </span>
                      </p>
                    </Box>
                    <table className="table">
                      <thead>
                        <tr>
                        <th> Table no.</th>
                          <th>Item Id</th>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {popupOrderData && Object.entries(popupOrderData?.cart).map(([itemId, cartItem], index) => (
                          <tr key={index}>
                            <td>{popupOrderData.table_id}</td>
                            <td>{itemId}</td>
                            <td>{cartItem[0]}</td>
                            <td>{cartItem[1]}</td>
                            <td>{cartItem[2]}</td>
                            <td>{cartItem[1] * cartItem[2]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "25px",
                        fontFamily: "Poppins",
                      }}
                    >
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>TOTAL</p>
                      <p style={{ fontSize: "25px", fontFamily: "Poppins", fontWeight: 600 }}>
                        RS {popupOrderData?.grand_total.toLocaleString()}
                      </p>
                    </div>
                  </div>
              </DialogContent>
          </Dialog>

              </Paper>
            </Grid>
    </Grid>
    </>
  );
};

export default Reserve;