import { ManageTypes } from "../ManageTypes/ManageTypes";

const initialState = {
    ManageResponseDataLoading:false,
    GetProductResponseData:[],
    GetProductResponseDataDetails:[],

    ManagerLoadingData:null,
    ManagerSuccessData: null,
    ManagerResponseData: [],
    ManagerFailData: null,

    ManagerDetailsLoading:false,

}

export default function ManageReducers(state = initialState, action) {
    switch (action.type) {
        case ManageTypes.MANAGE_LOADING_ADD_PRODUCT_DATA:
            return{
                ...state,
                ManageResponseDataLoading:true,
            }    
        case ManageTypes.MANAGE_SUCCESS_ADD_PRODUCT_DATA:
            return{
                ...state,
                ManageResponseDataLoading:false,
            }
        case ManageTypes.GET_PRODUCT_LOADING_DATA:
            return{
                ...state,
                ManageResponseDataLoading:true,
            }
        case ManageTypes.GET_PRODUCT_SUCCESS_DATA:
            return{
                ...state,
                GetProductResponseData: action.payload,
                ManageResponseDataLoading:false,
            }
        case ManageTypes.GET_PRODUCT_LOADING_DATA_DETAILS:
            return{
                ...state,
                ManageResponseDataLoading:true,
            }
        case ManageTypes.GET_PRODUCT_SUCCESS_DATA_DETAILS:
            return{
                ...state,
                GetProductResponseDataDetails: action.payload,
                ManageResponseDataLoading:false,
            }
        case ManageTypes.EDIT_MANAGER_LOADING_DATA_MAIN:
            return{
                ...state,
                ManagerLoadingData:true,
                ManagerSuccessData: false,
                ManagerResponseData: [],
                ManagerFailData: false,
            }
        case ManageTypes.EDIT_MANAGER_SUCCESS_DATA_MAIN:
            return{
                ...state,
                ManagerLoadingData: false,
                ManagerSuccessData: true,
                ManagerResponseData:  action.payload,
                ManagerFailData: false,
            }
        case ManageTypes.EDIT_MANAGER_FAIL_DATA_MAIN:
            return{
                ...state,
                ManagerLoadingData:false,
                ManagerSuccessData: false,
                ManagerResponseData:  [],
                ManagerFailData: true,
            }
        case ManageTypes.EDIT_MANAGER_SUCCESS_DATA_MAIN:
            return{
                ...state,
                ManagerLoadingData: null,
                ManagerSuccessData: null,
                ManagerResponseData: [],
                ManagerFailData: null,
            }
        case ManageTypes.EDIT_MANAGER_DETAILS_LOADING:
            return{
                ...state,
                ManagerDetailsLoading:true,
            }
        case ManageTypes.EDIT_MANAGER_DETAILS_SUCCESS:
            return{
                ...state,
                ManagerDetailsLoading:false,
            }
        default :
            return state;
    }
}