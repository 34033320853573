import { EditActionType } from "../EditLayoutType/EditActionType";

const initialState = {
    EditResponseDataLoading:false,
}

export default function EditReducers(state = initialState, action) {
    switch (action.type) {
        case EditActionType.TABLE_SCHEMA_LODING_UPDATED_DATA:
            return{
                ...state,
                EditResponseDataLoading:true,
            }    
        case EditActionType.TABLE_SCHEMA_SUCCESS_UPDATED_DATA:
            return{
                ...state,
                EditResponseDataLoading:false,
            }
        default :
            return state;
    }
}