import { Box, Button, FormControl, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../components/Navbar";
import UserDetails from "../../components/UserDetails";
import { useNavigate } from "react-router-dom";
//import { CategoryList, EditCategoriesName } from "./EditMenuActions/EditMenuApi";
import { TableData } from "../Reserve/ReserveActions/ReserveActionApi";
import { OrderHistoryData, menudata } from "../Order/OrderActions/OrderActionApi";
import { kothistory } from "../KotHistory/KotHistoryActions/KotHistoryActionApi";
import MessageBox from "../../components/MessageBox";
import loader from '../../assets/EditLoader.gif';
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Divider from '@mui/material/Divider';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CategoryList } from "../EditMenu/EditMenuActions/EditMenuApi";
import { edit_user_details, get_users_data } from "./EditUsersActions/EditUserActions";
import { socket_url } from "../../Redux/constants";
import { Email } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const EditUsers = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = sessionStorage?.getItem("Token");
    const product_id = sessionStorage?.getItem("ProductId");
    const email = sessionStorage?.getItem("Email");
    const role = sessionStorage?.getItem("Role");
    const bartender_limit = sessionStorage.getItem("bartender_limit");
    const chef_limit = sessionStorage.getItem("chef_limit");
    const user_limit = sessionStorage.getItem("user_limit");
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [Category,setCategory]=useState('');
    const [ws, setWs] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [UserName,setUserName] = useState('');
    const [Name,setName]=useState('');
    const [Password,setPassword]=useState('');
    const [Email,setEmail]=useState('');
    const [PhoneNo,setPhoneNo]=useState('');
    const [ShowPassword,setShowPassword]=useState(false);
    const [Popup,SetPopup]=useState(false);
    const [Type,setType]=useState(false);
    const [UserId,setUserId]=useState('');
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [UserHistoryData, setUserHistoryData] = useState([]);
    const [SelectRole,setSelectRole]=useState('');
    const [AddUser,setAddUser]=useState(false);
    const [AddChef,setAddChef]=useState(false);
    const [AddBartender,setAddBartender]=useState(false);
   
    const UserData = useSelector((state)=> state.editusers.UsersResponseData);
    const LoadingData = useSelector((state)=>state.editusers.UserDetailsLoading);
    const [userCount,setuserCount] = useState(0);
    const [chefCount,setchefCount] = useState(0);
    const [bartenderCount,setbartenderCount]=useState(0);

    const handleUserName =(event)=>{
      const name = event.target.value?.toLowerCase();
      const userExists = UserHistoryData.some(user => user.username.toLowerCase() === name);
  
      if (userExists) {
        setError('UserName already exists');
        event.target.focus();
        return;
      } else {
        setError('');
        setUserName(event.target.value);
        return;
      }
    }
    const handleName = (event)=>{
      setName(event.target.value);
    }
    const handleEmail = (event)=>{
      const emailid = event.target.value?.toLowerCase();
      const emailExists = UserHistoryData.some(user => user.email.toLowerCase() === emailid);
      if(emailExists)
        {
          setEmailError('Email already exists')
          event.target.focus();
          return;
        }
        else{
          setEmailError('');
          setEmail(event.target.value);
          return;
        }
    }
    const handlePassword = (event)=>{
      setPassword(event.target.value);
    }
    const handlePhoneNo = (event) =>{
      setPhoneNo(event.target.value);
    }
    const handleClickShowPassword = () => {
      setShowPassword(!ShowPassword)
  };
    const handleChangeRole =(event)=>{
      setSelectRole(event.target.value);
    }
    const handlePopupClose = () =>{
      setAddUser(false);
      setAddChef(false);
      setAddBartender(false);
      setSelectRole('');
      SetPopup(false);
    }

    const toTitleCase = (str) => {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };
    
    const handleCloseMessageBox = () => {
        setShowMessageBox(false);
      };

  const handleRowClick=(item)=>{
    if(item.role === 'user')
    {
      setAddUser(true)
    }
    else if(item.role ==='chef')
    {
      setAddChef(true)
    }
    else
    {
      setAddBartender(true)
    }
    setUserId(item.user_id)
    setUserName(item.username);
    setName(item.name);
    setEmail(item.email);
    setPassword(item.password);
    setPhoneNo(item.mobile);
    setSelectRole(item.role)
    setType("Edit");
    SetPopup(true);
  }
  const handleAddUser=()=>{
    let a=1
      if(parseInt(user_limit)>userCount)
      {
        if(a)
        {
          setSelectRole('user');
          a= a-1;
        }
        setAddUser(true)
      }
      if(parseInt(chef_limit)>chefCount)
      {
        if(a)
        {
          setSelectRole('chef');
          a= a-1;
        }
        setAddChef(true)
      }
      if(parseInt(bartender_limit)>bartenderCount)
      {
        if(a)
        {
          setSelectRole('bartender');
          a= a-1;
        }
        setAddBartender(true)
      }
    setUserId(0);
    setUserName('');
    setName('');
    setEmail('');
    setPassword('');
    setPhoneNo('');
    setType("Add");
    SetPopup(true);
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleUserUpdate=(type)=>{
    if(UserId!==''&&UserName!==''&&Password!==''&&Name!==''&&PhoneNo!==''&&Email!==''&&type!==''&&error===""&&emailError==="")
    {
      dispatch(edit_user_details(email,product_id,token,UserId,UserName,Password,Name,PhoneNo,Email,type,SelectRole));
      setAddUser(false);
      setAddChef(false);
      setAddBartender(false);
      SetPopup(false)
    }
    else{
      setMessage("Field Can't be Empty or wrong input");
      setMessageType("Error");
      setShowMessageBox(true);
    }
  }
    
      useEffect(()=>{
        if(!token ||token === "undefined" || token === "null" || token === "none")
          {
              navigate("/")
          }
          else
          {
            dispatch(get_users_data(email,product_id,token));
            if(role !== "manager")
            {
              navigate("/reserve")
            }
             if(UserData.length === 0)
              {
                dispatch(TableData(product_id, email, token));
                dispatch(menudata(product_id,email,token));
                dispatch(OrderHistoryData(email, product_id, token));
                dispatch(kothistory(email, product_id, token));
                dispatch(CategoryList(email,product_id,token));
              } 
          }
                // Initialize WebSocket connection
                
        const socket = new WebSocket(socket_url);
        setWs(socket);
    
        socket.onopen = () => {
         // console.log("WebSocket connection established");
        };
    
        socket.onmessage = (event) => {
          try {
            const message = event.data;
          // Handle incoming messages and update state as needed
          if (message === "edit_user_details") {
            dispatch(get_users_data(email,product_id,token)); // Update KOT history on new message
           
          }
        }
        catch (error) {
          console.error("Error parsing message:", error);
        }
      }
      },[dispatch])

      useEffect(()=>{
        if(UserData)
        {
          // Iterate through the userdata array
          let user1=0;
          let chef=0;
          let bartender=0;
          UserData.forEach(user => {
            // Check the role of each user and increment the corresponding counter
            if (user.role === 'user') {
                user1++;
            } else if (user.role === 'chef') {
              chef++;
            }else if (user.role === 'bartender') {
              bartender++;
          }
        });
          setuserCount(user1);
          setchefCount(chef);
          setbartenderCount(bartender);
          setUserHistoryData(UserData);
        }
      },[UserData])
    
    return(
        <>
        <Grid container spacing={1} sx={{filter: LoadingData ? "blur(5px)" : "none",pointerEvents: LoadingData ? "none" : "auto"}}>
            {LoadingData && (
                <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                }}
                >
                <img src={loader} alt="Loader" />
                </div>
            )}
            <Grid item xs={12} md={3} lg={2}>
                <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                    <Navbar active="editusers" />
                </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"auto"}} className='container-main'>
                <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserDetails/>
                    </Grid>
                   <Grid item xs={12}>
                      <Paper elevation={3} sx={{height: '85vh'}}>
                        <Grid item xs={12}>
                          <Typography
                              sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    color: '#2E2E2E',
                                    marginLeft: "4.5%",
                                    paddingTop: "2%"
                                  }}
                                    variant="h4"
                                    noWrap
                                    component="div"
                          >
                              All Users
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{height:"70%",marginLeft:"1.5%"}}>
                        <div className="table-container">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Username</th>
                                  <th>Role</th>
                                </tr>
                              </thead>
                              <tbody>
                              {UserHistoryData && UserHistoryData.length > 0 ? (
                                  UserHistoryData.map((item) => (
                                    <tr key={item.user_id} onClick={() => handleRowClick(item)}>
                                      <td>{item.username}</td>
                                      <td>{item.role ==='user'?'Waiter':toTitleCase(item.role)}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="6">No Users data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Grid>
                        <Grid item xs={12} sx={{display:"flex",justifyContent:"flex-end",marginRight:"1.5%"}}>
                          <Button
                            variant="contained"
                            style={{
                              borderRadius: "12px", // Adjust the border radius as needed
                              fontFamily: "Poppins", // Set the font family
                              fontSize: "18px", // Adjust the font size as needed
                              backgroundColor: "#0093fb",
                              textAlign: "center" ,
                              width:"20%"// Set the background color
                            }}
                            onClick={()=>handleAddUser()}
                          >
                            Add User
                          </Button>
                        </Grid>
                      </Paper>
                   </Grid>
                </Grid>
            </Grid>
                <Dialog
                  open={Popup}
                  onClose={handlePopupClose}
                  TransitionComponent={Transition}
                  fullWidth
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      width: "45rem", // Adjust the width as needed to make it square
                      height: "auto", // Adjust the height as needed to make it square
                      margin: "auto", // Center horizontally and vertically
                      borderRadius: '23px', // Remove border radius to make it square
                      position: "fixed",
                    },
                  }}
                >
                  <DialogTitle>
                    <div style={{ position: "relative" }}>
                      <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>

                    <DialogContent className="dialog-content">
                        <Box sx={{ fontSize: "40px", color: "#5E5E5E"}}>
                          <p style={{fontFamily: "Poppins", fontWeight:600 }}>
                              <span className="label">{Type} User</span>
                            </p>
                          </Box>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                            <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                            >
                                                   User Name :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={UserName}
                                                onChange={handleUserName}
                                                error={!!error}
                                                helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                            >
                                                    Name :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={Name}
                                                onChange={handleName}
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                   paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                            >
                                                    Email :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={Email}
                                                onChange={handleEmail}
                                                error={!!emailError}
                                                helperText={emailError}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    
                                            >
                                                    Password :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={Password}
                                                type={ShowPassword ? 'text' : 'password'}
                                                onChange={handlePassword}
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {ShowPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    
                                            >
                                                    Phone No:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                value={PhoneNo}
                                                onChange={handlePhoneNo}
                                                type="number"
                                                //error={!!error}
                                                //helperText={error}
                                                sx={{
                                                    width:"90%",
                                                    textAlign: "center",
                                                    justifyContent:"center",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                          borderColor: '#5E5E5E', // Default border color
                                                        },
                                                        '&:hover fieldset': {
                                                          borderColor: '#5E5E5E', // Border color on hover
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                          borderColor: '#5E5E5E', // Border color when focused
                                                        },
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        height: 10, // Adjust the height here
                                                      },
                                                      '& .MuiInputBase-input': {
                                                        padding: '15px 10px', // Adjust the padding inside the input
                                                      },
                                                    }}
                                                    InputProps={{
                                                      style: {
                                                        height: '50px',
                                                        color: "#5E5E5E",
                                                      },
                                                    }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Poppins',
                                                    fontWeight: '400',
                                                    color: '#2E2E2E',
                                                    marginLeft: "4.5%",
                                                    paddingTop: "5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    
                                            >
                                                    Role :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <FormControl fullWidth sx={{ fontSize: '1rem'}}>
                                          <Select
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={SelectRole}
                                            onChange={handleChangeRole}
                                            sx={{ height: '32px',color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins',width:"90%"}}
                                          >
                                            {AddUser?(<MenuItem value={'user'} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>Waiter</MenuItem>):null}
                                            {AddChef?(<MenuItem value={'chef'} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>Chef</MenuItem>):null}
                                            {AddBartender?(<MenuItem value={'bartender'} sx={{color:'#5E5E5E',fontSize:'1rem',fontFamily:'poppins'}}>Bartender</MenuItem>):null}
                                          </Select>
                                        </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                  <DialogActions className="dialog-actions" style={{display:"flex",justifyContent: 'right' }}>
                    {Type === 'Edit'?(
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#8E0515",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={()=>handleUserUpdate("delete")}
                    >
                      Delete User
                    </Button>):null}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        borderRadius: "12px", // Adjust the border radius as needed
                        fontFamily: "Poppins", // Set the font family
                        fontSize: "18px", // Adjust the font size as needed
                        backgroundColor: "#0093fb",
                        textAlign: "center" ,
                        width:"30%"// Set the background color
                      }}
                      onClick={()=>handleUserUpdate(Type.toLowerCase())}
                    >
                      {Type} User
                    </Button>
                  </DialogActions>
                </Dialog>
            {showMessageBox && (
                <MessageBox
                    message={message}
                    type={messageType}
                    duration={3000}
                    onClose={handleCloseMessageBox}
                />
            )}
        </Grid>
        </>
    )


}
export default EditUsers;