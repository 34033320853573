import { EditUsersType } from '../EditUsersType/EditUsersType';
import Axios from 'axios';
import { base_url } from "../../../Redux/constants";

export const get_users_data=(email,product_id,token) =>(dispatch) =>{
    Axios.get(`${base_url}/get_user_details?product_id=${product_id}&email=${email}`,{
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => {
      dispatch({
        type: EditUsersType.EDIT_USERS_SUCCESS_DATA_MAIN,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: EditUsersType.EDIT_USERS_FAIL_DATA_MAIN,
      });
    });
}

export const edit_user_details=(email,product_id,token,user_id,username,password,name,mobile,user_email,type,role)=>{
    
    const data={
      "email":email,	
      "product_id":product_id,
      "user_id":user_id,
      "username":username,
      "password":password,
      "name":name,
      "mobile":mobile,
      "user_email":user_email,
      "type":type,
      "role":role
      }
      return(dispatch)=>{
        dispatch({
            type: EditUsersType.EDIT_USERS_DETAILS_LOADING,
        })
        Axios.post(`${base_url}/edit_user_details`,data,{
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          })
          .then((response) =>{
            dispatch({
                type: EditUsersType.EDIT_USERS_DETAILS_SUCCESS,
            })
        })
        .catch((error) => {
            console.error("Error fetching menu data:", error);
        });
        }
  }