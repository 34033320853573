export const TYPES = {
 
    // -------------- KOT HISTORY ---------------------------
    KOT_HISTORY_LODING_DATA_MAIN: "KOT_HISTORY_LODING_DATA_MAIN",
    KOT_HISTORY_SUCCESS_DATA_MAIN: "KOT_HISTORY_SUCCESS_DATA_MAIN",
    KOT_HISTORY_FAIL_DATA_MAIN: "KOT_HISTORY_FAIL_DATA_MAIN",
    KOT_HISTORY_CLEAR_DATA_MAIN: "KOT_HISTORY_CLEAR_DATA_MAIN",

    //--------------- COMPLETE KOT --------------------
    COMPLETE_KOT_LOADING:"COMPLETE_KOT_LOADING",
    COMPLETE_KOT_SUCCESS:"COMPLETE_KOT_SUCCESS",
    COMPLETE_KOT_FAIL:"COMPLETE_KOT_FAIL",
    COMPLETE_KOT_CLEAR:"COMPLETE_KOT_CLEAR"

}