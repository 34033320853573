export const TYPES = {
 
    // -------------- BOT HISTORY ---------------------------
    BOT_HISTORY_LODING_DATA_MAIN: "BOT_HISTORY_LODING_DATA_MAIN",
    BOT_HISTORY_SUCCESS_DATA_MAIN: "BOT_HISTORY_SUCCESS_DATA_MAIN",
    BOT_HISTORY_FAIL_DATA_MAIN: "BOT_HISTORY_FAIL_DATA_MAIN",
    BOT_HISTORY_CLEAR_DATA_MAIN: "BOT_HISTORY_CLEAR_DATA_MAIN",

    //--------------- COMPLETE BOT --------------------
    COMPLETE_BOT_LOADING:"COMPLETE_BOT_LOADING",
    COMPLETE_BOT_SUCCESS:"COMPLETE_BOT_SUCCESS",
    COMPLETE_BOT_FAIL:"COMPLETE_BOT_FAIL",
    COMPLETE_BOT_CLEAR:"COMPLETE_BOT_CLEAR"

}