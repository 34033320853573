import { EditMenuActionType } from '../EditMenuType/EditMenuActionType';
import Axios from 'axios';
import { base_url } from "../../../Redux/constants";

export const CategoryList=(email,product_id,token) => (dispatch) =>{
    Axios.get(`${base_url}/item_category?product_id=${product_id}&email=${email}`,{
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => {
      dispatch({
        type: EditMenuActionType.LIST_CATEGORIES_SUCCESS_DATA,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: EditMenuActionType.LIST_CATEGORIES_FAIL_DATA,
      });
    });
};

export const EditMenuData1=(email,product_id,token,add,remove)=>{
    
  const data={
    "email":email,	
    "product_id":product_id,
    "add":add,
    "remove":remove,
    }
    console.log(add)
    return(dispatch)=>{
      dispatch({
          type: EditMenuActionType.EDIT_LIST_CATEGORIES_LOADING_DATA,
      })
      Axios.post(`${base_url}/edit_menu`,data,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
        .then((response) =>{
          dispatch({
              type: EditMenuActionType.EDIT_LIST_CATEGORIES_SUCCESS_DATA,
          })
      })
      .catch((error) => {
          console.error("Error fetching menu data:", error);
      });
      }
}

export const EditCategoriesName=(email,product_id,token,category,category_type,type)=>{
    
  const data={
    "email":email,	
    "product_id":product_id,
    "category_name":category,
    "category_type":category_type,
    "type":type,
    }
    return(dispatch)=>{
      dispatch({
          type: EditMenuActionType.EDIT_CATEGORIES_LOADING_NAME,
      })
      Axios.post(`${base_url}/edit_category`,data,{
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        })
        .then((response) =>{
          dispatch({
              type: EditMenuActionType.EDIT_CATEGORIES_SUCCESS_NAME,
          })
      })
      .catch((error) => {
          console.error("Error fetching menu data:", error);
      });
      }
}