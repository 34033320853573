export const EditMenuActionType={
    LIST_CATEGORIES_LOADING_DATA:"LIST_CATEGORIES_LOADING_DATA",
    LIST_CATEGORIES_SUCCESS_DATA:"LIST_CATEGORIES_SUCCESS_DATA",
    LIST_CATEGORIES_FAIL_DATA:"LIST_CATEGORIES_FAIL_DATA",
    LIST_CATEGORIES_CLEAN_DATA:"LIST_CATEGORIES_CLEAN_DATA",

    EDIT_CATEGORIES_LOADING_NAME:"EDIT_CATEGORIES_LOADING_NAME",
    EDIT_CATEGORIES_SUCCESS_NAME:"EDIT_CATEGORIES_SUCCESS_NAME",

    EDIT_LIST_CATEGORIES_LOADING_DATA:"EDIT_LIST_CATEGORIES_LOADING_DATA",
    EDIT_LIST_CATEGORIES_SUCCESS_DATA:"EDIT_LIST_CATEGORIES_SUCCESS_DATA",
}