import { EditUsersType } from "../EditUsersType/EditUsersType"

const initialState={

    UsersLoadingData:null,
    UsersSuccessData: null,
    UsersResponseData: [],
    UsersFailData: null,

    UserDetailsLoading:false,
    
}

export default function EditUsersReducers(state = initialState, action) {
    switch (action.type) {
        case EditUsersType.EDIT_USERS_LOADING_DATA_MAIN:
            return{
                ...state,
                UsersLoadingData:true,
                UsersSuccessData: false,
                UsersResponseData: [],
                UsersFailData: false,
            }
        case EditUsersType.EDIT_USERS_SUCCESS_DATA_MAIN:
            return{
                ...state,
                UsersLoadingData: false,
                UsersSuccessData: true,
                UsersResponseData:  action.payload,
                UsersFailData: false,
            }
        case EditUsersType.EDIT_USERS_FAIL_DATA_MAIN:
            return{
                ...state,
                UsersLoadingData:false,
                UsersSuccessData: false,
                UsersResponseData:  [],
                UsersFailData: true,
            }
        case EditUsersType.EDIT_USERS_SUCCESS_DATA_MAIN:
            return{
                ...state,
                UsersLoadingData: null,
                UsersSuccessData: null,
                UsersResponseData: [],
                UsersFailData: null,
            }
        case EditUsersType.EDIT_USERS_DETAILS_LOADING:
            return{
                ...state,
                UserDetailsLoading:true,
            }
        case EditUsersType.EDIT_USERS_DETAILS_SUCCESS:
            return{
                ...state,
                UserDetailsLoading:false,
            }
        default :
            return state;
    }
}
