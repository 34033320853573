import { EditMenuActionType } from "../EditMenuType/EditMenuActionType";

const initialState = {
    ListLoadingData: null,
    ListSuccessData: null,
    ListResponseData: [],
    ListFailData: null,

    EditCateoriesNameLoading:false,

    EditResponseListDataLoading:false,
}

export default function EditMenuReducers(state = initialState, action) {
    switch (action.type) {
        case EditMenuActionType.LIST_CATEGORIES_LOADING_DATA:
            return{
                ...state,
                ListLoadingData:true,
                ListSuccessData: false,
                ListResponseData: [],
                ListFailData: false,
            }    
        case EditMenuActionType.LIST_CATEGORIES_SUCCESS_DATA:
            return{
                ...state,
                ListLoadingData:false,
                ListSuccessData: true,
                ListResponseData: action.payload,
                ListFailData: false,
            }
        case EditMenuActionType.LIST_CATEGORIES_FAIL_DATA:
            return{
                ...state,
                ListLoadingData:false,
                ListSuccessData: false,
                ListResponseData: [],
                ListFailData: true,
            }
        case EditMenuActionType.LIST_CATEGORIES_CLEAN_DATA:
            return{
                ...state,
                ListLoadingData: null,
                ListSuccessData: null,
                ListResponseData: [],
                ListFailData: null,
            }
        case EditMenuActionType.EDIT_LIST_CATEGORIES_LOADING_DATA:
            return{
                ...state,
                EditResponseListDataLoading:true,
            }    
        case EditMenuActionType.EDIT_LIST_CATEGORIES_SUCCESS_DATA:
            return{
                ...state,
                EditResponseListDataLoading:false,
            }
        case EditMenuActionType.EDIT_CATEGORIES_LOADING_NAME:
            return{
                ...state,
                EditCateoriesNameLoading:true,
            }    
        case EditMenuActionType.EDIT_CATEGORIES_SUCCESS_NAME:
            return{
                ...state,
                EditCateoriesNameLoading:false,
            }
        default :
            return state;
    }
}