export const ReserveActionType={
    TABLE_SCHEMA_LODING_DATA_MAIN:'TABLE_SCHEMA_LOADING_DATA_MAIN',
    TABLE_SCHEMA_SUCCESS_DATA_MAIN:'TABLE_SCHEMA_SUCCESS_DATA_MAIN',
    TABLE_SCHEMA_FAIL_DATA_MAIN:'TABLE_SCHEMA_FAIL_DATA_MAIN',
    TABLE_SCHEMA_CLEAR_DATA_MAIN:'TABLE_SCHEMA_CLEAR_DATA_MAIN',

    TABLE_SCHEMA_LODING_DATA:'TABLE_SCHEMA_LOADING_DATA',
    TABLE_SCHEMA_SUCCESS_DATA:'TABLE_SCHEMA_SUCCESS_DATA',
    TABLE_SCHEMA_FAIL_DATA:'TABLE_SCHEMA_FAIL_DATA',
    TABLE_SCHEMA_CLEAR_DATA:'TABLE_SCHEMA_CLEAR_DATA',

    // ------------ ORDER NUMBER ---------------
    CURRENT_ORDER_ID:"CURRENT_ORDER_ID",

    // ------------ CUSTOMER_DATA --------------
    CUSTOMER_DATA:"CUSTOMER_DATA"
}