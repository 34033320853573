import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Bothistory, CompleteBot } from "./BotHistoryActions/BotHistoryActions";

import "./BotHistory.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/Navbar";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import { OrderHistoryData, menudata } from "../Order/OrderActions/OrderActionApi";
import { TableData } from "../Reserve/ReserveActions/ReserveActionApi";
import UserDetails from "../../components/UserDetails";
import ElapsedTimeCounter from './ElapsedTimeCounter'; 
import { CategoryList } from "../EditMenu/EditMenuActions/EditMenuApi";
import { get_users_data } from "../EditUsers/EditUsersActions/EditUserActions";
import { socket_url } from "../../Redux/constants";
import { kothistory } from "../KotHistory/KotHistoryActions/KotHistoryActionApi";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BotHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = sessionStorage?.getItem("Token");
  const email = sessionStorage?.getItem("Email");
  const product_id = sessionStorage?.getItem("ProductId");
  const [ws, setWs] = useState(null);
  const botHistoryState = useSelector((state) => state.botHistory);
  const {
    BotHistoryLoadingData,
    BotHistorySuccessData,
    BotHistoryResponseData, 
    BotHistoryFailData,
  } = botHistoryState;
  const [BotHistoryData, setBotHistoryData] = useState([]);
  const [checkboxState, setCheckboxState] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [lengthOfBotData,setLengthOfBotData] = useState(0);

  useEffect(() => {
    if(!token ||token === "undefined" || token === "null" || token === "none")
      {
          navigate("/")
      }
      else
      {
        dispatch(Bothistory(email,product_id,token));
        if(BotHistoryResponseData.length === 0)
          {
            dispatch(kothistory(email,product_id,token));
            dispatch(get_users_data(email,product_id,token));
            dispatch(menudata(product_id,email,token));
            dispatch(TableData(product_id, email, token));
            dispatch(OrderHistoryData(email, product_id, token));
            dispatch(CategoryList(email,product_id,token));
          }
      }
          // Initialize WebSocket connection
    const socket = new WebSocket(socket_url);
    setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      console.log("Raw message:", event.data); // Log the raw message
      try {
        const message = event.data;
        console.log("Parsed message:", message);
      // Handle incoming messages and update state as needed
      if (message === "send_bot" || message === "kot_item_status_update" || message === "transfer_table") {
        dispatch(Bothistory(email, product_id, token)); // Update Bot history on new message
      }
    }
    catch (error) {
      console.error("Error parsing message:", error);
    }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close(); // Clean up WebSocket connection on unmount
    }
  }, [dispatch]);

  useEffect(() => {
    if (BotHistoryResponseData && BotHistoryResponseData.data) {
      const updatedData = BotHistoryResponseData.data.map((order) => {
        // Check if all items in the cart have the 3rd index value as 1
        const allItemsCompleted = Object.values(order.cart).every(
          (cartItem) => cartItem[3] === 1
        );
  
        // Update order_status to "completed" if all items are completed
        return {
          ...order,
          order_status: allItemsCompleted ? "completed" : order.order_status,
          order_out_time: allItemsCompleted ? new Date().toLocaleString() : order.order_out_time,
        };
      });
  
      setBotHistoryData(updatedData); // Update the state with the modified data
    }
  }, [BotHistoryResponseData]);
  const handleCheckboxClick = (itemId,cartdata) => {
    setCheckboxState(prevState => ({
      ...prevState,
      [itemId]: [
        ...prevState[itemId].slice(0, 3), // Keep first three elements unchanged
        1, // Set the fourth element (cartItem[3]) to 1
      ]
    }));
    cartdata[itemId][3]=1;
    handleCompleteBot(cartdata); 
  };
  const handleRowClick = (orderData) => {
    setCheckboxState(orderData.cart);
    setPopupData(orderData);
    setOpenPopup(true);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
    setPopupData(null);
  };
  const handleCompleteBot = async (data) => {
    try {
      if (popupData && popupData.kot_id) {
        await dispatch(CompleteBot(email,product_id,popupData.kot_id,token,data));   
        dispatch(Bothistory(email,product_id,token)); // Call bothistory after completing the Bot
        dispatch(OrderHistoryData(email, product_id, token));
      }
    } catch (error) {
      console.error('Error completing Bot:', error);
    }
  };
  

  return (
    <Grid container spacing={0}>
      <Grid item xs={2}>
        <Navbar active="botHistory" />
      </Grid>
      <Grid item xs={12}md={12} lg={10} className="table-container-1">
      <Grid container spacing={0} sx={{paddingRight:"1.5%",paddingLeft:"1.5%"}}>
          <Grid item xs={12} md={12} lg={12}>
              <UserDetails/>
          </Grid>
      </Grid>
        <div className="table-container">
          {BotHistoryLoadingData && <p>Loading...</p>}
          <table className="table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>BOT ID</th>
                <th>Table</th>
                
                <th>Timer</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
            {BotHistoryData && BotHistoryData.length > 0 ? (
              BotHistoryData.map((item) => (
    <tr key={item.kot_id} onClick={() => handleRowClick(item)}>
      <td>{item.order_id}</td>
      <td>{item.kot_id}</td>
      <td>{item.table_id}</td>
      
      <td>
        {item.order_out_time ? "---" : <ElapsedTimeCounter orderInTime={item.order_in_time} withEffects={true} />
      }
      </td>
      <td>{item.order_status || "Not available"}</td>
    </tr>
  ))
            ):(
              <tr>
                  <td colSpan="6">No Bot history data available</td>
              </tr>
            )}
            </tbody>
          </table>

          <Dialog
            open={openPopup}
            onClose={handlePopupClose}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>
              <div style={{ position: "relative" }}>
                <IconButton
                  aria-label="close"
                  onClick={handlePopupClose}
                  style={{ position: "absolute", top: 0, right: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>

            {popupData && (
              <DialogContent className="dialog-content">
                {popupData && (
                  <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
  <p style={{ fontFamily: "Poppins", fontWeight: 300, marginRight: "10px" }}>IN TIME: {popupData.order_in_time} </p>
  <p style={{ fontFamily: "Poppins", fontWeight: 300 }}>
    OUT TIME: {popupData.order_out_time ? popupData.order_out_time : "---"}
  </p>
</div>


                    <Box sx={{ fontSize: "25px", color: "#0093FB" }}>
                    
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>
                        <span className="label">ORDER ID :</span>
                        <span className="value" style={{ marginRight: "20px" }}>
                          {popupData.order_id}
                        </span>
                        <span className="label">Bot ID :</span>
                        <span className="value">{popupData.kot_id}</span>
                      </p>
                    </Box>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Item Id</th>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                          <th>Notes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(popupData.cart).map(([itemId, cartItem], index) => (
                          <tr key={index}>
                            {console.log(cartItem)}
                            <td>{itemId}</td>
                            <td>{cartItem[0]}</td>
                            <td>{cartItem[1]}</td>
                            <td>{cartItem[2]}</td>
                            <td>{cartItem[1] * cartItem[2]}</td>
                            <td>{popupData.notes || "Not available"}</td>
                             <td>
                              <input 
                                type="checkbox" 
                                checked={checkboxState[itemId][3] === 1} 
                                disabled={checkboxState[itemId][3] === 1} 
                                onChange={() => handleCheckboxClick(itemId,popupData.cart)}
                                style={{ cursor: 'pointer',width: '80%' }} 
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      }}
                    >
                      <p style={{ fontFamily: "Poppins", fontWeight: 600 }}>TOTAL</p>
                      

                      <p style={{ fontSize: "25px", fontFamily: "Poppins", fontWeight: 600 }}>
                        RS {popupData.total_amount.toLocaleString()}
                      </p>
                    </div>
                  </div>
                )}
              </DialogContent>
            )}
            
          </Dialog>
        </div>
      </Grid>
    </Grid>
  );
};

export default BotHistory;
