import { TYPES } from "../KotHistoryType/KotHistoryType";
import Axios from 'axios';
import { base_url } from "../../../Redux/constants";
export const kothistory = (email,product_id,token) => (dispatch) =>{
    //dispatch(KotHistoryLoading());
    Axios.get(`${base_url}/kot_history?product_id=${product_id}&email=${email}`,{
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    })
    .then((response) => {
      dispatch({
        type: TYPES.KOT_HISTORY_SUCCESS_DATA_MAIN,
        payload: response.data,
      });
      if(response.data.length === 0)
      {
          //console.log("done");
      }
      else{
          //console.log("not done");
      }
    })
    .catch((error) => {
      dispatch({
        type: TYPES.KOT_HISTORY_FAIL_DATA_MAIN,
      });
    });
};



//------------------KOT HISTORY LOADING --------------------------

export const KotHistoryLoading = () => (dispatch) => {
    dispatch({
      type: TYPES.KOT_HISTORY_LODING_DATA_MAIN,
    });
  };
  
  //------------------------- KOT HISTORY CLEAR DATA --------------------
  
  export const KotHistoryClearData = () => (dispatch) => {
    dispatch({
      type: TYPES.KOT_HISTORY_CLEAR_DATA_MAIN,
    });
  };

  
export const CompleteKot = (email,product_id,kot_id,token,cart) => (dispatch) => {
  dispatch(CompleteKotLoading());

  // Define the data object with the parameters
  const data = {
      "email":email,
      "product_id": product_id,
      "kot_id": kot_id,
      "cart":cart
  };

  Axios.post(`${base_url}/kot_item_status_update`, data,{
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  })
      .then((response) => {
        console.log(response);
          dispatch({
              type: TYPES.COMPLETE_KOT_SUCCESS,
              payload: response.data,
          });
      })
      .catch((error) => {
          dispatch({
              type: TYPES.COMPLETE_KOT_FAIL,
          });
      });
};


  //------------------COMPLETE KOT LOADING --------------------------

export const CompleteKotLoading = () => (dispatch) => {
  dispatch({
    type: TYPES.COMPLETE_KOT_LOADING,
  });
};

//------------------------- COMPLETE KOT CLEAR DATA --------------------

export const CompleteKotClearData = () => (dispatch) => {
  dispatch({
    type: TYPES.COMPLETE_KOT_CLEAR,
  });
};





