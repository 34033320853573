import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { ReactComponent as EditLayout } from './EditLayout.svg';
import { ReactComponent as KotHistory } from './KotHistory.svg';
import { ReactComponent as OrderHistory } from './OrderHistory.svg';
import { ReactComponent as Reserve } from './Reserve.svg';
import { useNavigate } from 'react-router-dom';
import { resetState } from './actions';
import { useDispatch } from 'react-redux';

const Navbar = ({ active }) => {
  const dispatch = useDispatch();
  const username = sessionStorage.getItem("Username")
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(active);
  const bartender_limit = sessionStorage.getItem("bartender_limit");
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.sidebar')) {
        setIsOpen(false);
      }
    };

    if (isMobile) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, isMobile]);

  const handleOptionClick = (option) => {
      setSelectedOption(option);
      setIsOpen(false);
      navigate(`/${option}`);
  };
  const handleCompanyClick = async(option)=>{
    if(username === 'admin')
      {
        dispatch(resetState());
        sessionStorage.setItem('Role','admin');
        await window.location.reload();
        setSelectedOption(option);
        setIsOpen(false);
        navigate(`/dashboard`);
      }
      else
      {
        setSelectedOption(option);
        setIsOpen(false);
        navigate(`/${option}`);
      }
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="sidebar-container">
      <div className={`sidebar ${isOpen ? 'open' : ''} ${isMobile ? 'mobile' : ''}`}>
        <div className="sidebar-header">
          <h2 onClick={() => handleCompanyClick('reserve')}>
            <span>Bistro</span><span>Buddy</span>
          </h2>
        </div>
        <div className="sidebar-options">
          {sessionStorage.getItem("Role")==="customer"?(
            <>
              <div
                className={`sidebar-option ${selectedOption === 'reserve' ? 'selected' : ''}`}
                onClick={() => handleOptionClick('customerOrder')}
              >
                <Reserve className="icon-reserve" />
                <span>Order</span>
              </div>
              <div
                className={`sidebar-option ${selectedOption === 'customerOrderHistory' ? 'selected' : ''}`}
                onClick={() => handleOptionClick('customerOrderHistory')}
              >
                <OrderHistory className="icon-order-history" />
                <span>Order History</span>
              </div>
            </>
          ):null}
          {sessionStorage.getItem("Role") === "user" ?(
            <>
          <div
            className={`sidebar-option ${selectedOption === 'reserve' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('reserve')}
          >
            <Reserve className="icon-reserve" />
            <span>Reserve</span>
          </div>
          <div
            className={`sidebar-option ${selectedOption === 'kotHistory' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('kotHistory')}
          >
            <KotHistory className="icon-kot-history" />
            <span>KOT History</span>
          </div>
          {bartender_limit>0?(
              <div
              className={`sidebar-option ${selectedOption === 'botHistory' ? 'selected' : ''}`}
              onClick={() => handleOptionClick('botHistory')}
            >
              <KotHistory className="icon-kot-history" />
              <span>BOT History</span>
            </div>
          ):null}
  
          <div
            className={`sidebar-option ${selectedOption === 'orderHistory' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('orderHistory')}
          >
            <OrderHistory className="icon-order-history" />
            <span>Order History</span>
          </div>
          </>
        ):null}
        {sessionStorage.getItem("Role") === "admin"?(
          <>
          <div
          className={`sidebar-option ${selectedOption === 'dashboard' ? 'selected' : ''}`}
          onClick={() => handleOptionClick('dashboard')}
        >
          <EditLayout className="icon-edit-layout" />
          <span>Dashboard</span>
        </div>
        <div
          className={`sidebar-option ${selectedOption === 'manage' ? 'selected' : ''}`}
          onClick={() => handleOptionClick('manage')}
        >
          <EditLayout className="icon-edit-layout" />
          <span>Manage</span>
        </div>
        <div
          className={`sidebar-option ${selectedOption === 'productdetails' ? 'selected' : ''}`}
          onClick={() => handleOptionClick('productdetails')}
        >
          <EditLayout className="icon-edit-layout" />
          <span>Product Details</span>
        </div>
        <div
          className={`sidebar-option ${selectedOption === 'editmanager' ? 'selected' : ''}`}
          onClick={() => handleOptionClick('editmanager')}
        >
          <EditLayout className="icon-edit-layout" />
          <span>Edit Manager</span>
        </div>
          </>
        ):null}
          {sessionStorage.getItem("Role") === "manager"?(
            <>
            <div
            className={`sidebar-option ${selectedOption === 'reserve' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('reserve')}
          >
            <Reserve className="icon-reserve" />
            <span>Reserve</span>
          </div>
          <div
            className={`sidebar-option ${selectedOption === 'kotHistory' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('kotHistory')}
          >
            <KotHistory className="icon-kot-history" />
            <span>KOT History</span>
          </div>
          {bartender_limit>0?(
              <div
              className={`sidebar-option ${selectedOption === 'botHistory' ? 'selected' : ''}`}
              onClick={() => handleOptionClick('botHistory')}
            >
              <KotHistory className="icon-kot-history" />
              <span>BOT History</span>
            </div>
          ):null}
          <div
            className={`sidebar-option ${selectedOption === 'orderHistory' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('orderHistory')}
          >
            <OrderHistory className="icon-order-history" />
            <span>Order History</span>
          </div>
            <div
            className={`sidebar-option ${selectedOption === 'editLayout' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('editLayout')}
          >
            <EditLayout className="icon-edit-layout" />
            <span>Edit Layout</span>
          </div>
          <div
            className={`sidebar-option ${selectedOption === 'editMenu' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('editMenu')}
          >
            <EditLayout className="icon-edit-layout" />
            <span>Edit Menu</span>
          </div>
          <div
            className={`sidebar-option ${selectedOption === 'editusers' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('editusers')}
          >
            <EditLayout className="icon-edit-layout" />
            <span>Edit Users</span>
          </div>
          </>
          ):null}
          {sessionStorage.getItem("Role") === "chef"?(
            <div
            className={`sidebar-option ${selectedOption === 'kotHistory' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('kotHistory')}
          >
            <KotHistory className="icon-kot-history" />
            <span>KOT History</span>
          </div>
          ):null}
          {sessionStorage.getItem("Role") === "bartender"?(
            <div
            className={`sidebar-option ${selectedOption === 'botHistory' ? 'selected' : ''}`}
            onClick={() => handleOptionClick('botHistory')}
          >
            <KotHistory className="icon-kot-history" />
            <span>BOT History</span>
          </div>
          ):null}

        </div>
      </div>
      {isMobile && !isOpen && (
  <div className="mobile-header-container">
    <button className="sidebar-toggle-mobile" onClick={toggleSidebar}>
      <i className="icon-toggle">&#9776;</i>
    </button>
      <h2>
        <span>Bistro</span><span>Buddy</span>
      </h2>
  </div>
)}

      
    </div>
  );
};

export default Navbar;


