import { Box, Grid } from "@mui/material";
import React from "react";
import Navbar from "../../../components/Navbar";
import UserDetails from "../../../components/UserDetails";
const ProductDetails = () =>{
    return(
    <>
        <Grid container spacing={1} sx={{/* filter: loadingData ? "blur(5px)" : "none",pointerEvents: loadingData ? "none" : "auto", */height:"100vh"}}>
           {/*  {loadingData && (
                <div
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                }}
                >
                <img src={loader} alt="Loader" />
                </div>
            )} */}
            <Grid item xs={12} md={3} lg={2}>
                <Box sx={{ width: 'inherit' }}> {/* Ensure the Box inside Grid item takes full width */}
                    <Navbar active="productdetails" />
                </Box>
            </Grid>
            <Grid item xs={12}md={12} lg={10} style={{height:"99vh"}} className='container-main'>
                <Grid container spacing={1} sx={{marginBottom:"3%",paddingRight:"1.5%",paddingLeft:"1.5%"}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <UserDetails/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}
export default ProductDetails;