// src/MessageBox.js
import React, { useEffect } from 'react';
import './MessageBox.css';

const MessageBox = ({ message, type, duration, onClose }) => {
  useEffect(() => {
    if (duration) {
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  return (
    <div className={`message-box ${type}`}>
      {message}
    </div>
  );
};

export default MessageBox;
