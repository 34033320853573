import { combineReducers } from 'redux';
import OrderReducers from "../Pages/Order/OrderReducers/OrderReducers";// Import your order reducer
import kotHistoryReducer from '../Pages/KotHistory/KotHistoryReducers/KotHistoryReducers';
import ReserveReducer from '../Pages/Reserve/ReserveReducers/ReserveReducers';
import LoginReducer from '../Pages/Login/LoginReducer/LoginReducer';
import EditReducers from '../Pages/EditLayout/EditLayoutReducers/EditReducers';
import EditMenuReducers from '../Pages/EditMenu/EditMenuReserves/EditMenuReducers';
import { RESET_STATE } from '../components/actions/types';
import EditUsersReducers from '../Pages/EditUsers/EditUsersReserves/EditUsersReducers';
import ManageReducers from '../Pages/Admin/Manage/ManageReducers/ManageReducers';
import BotHistoryReducer from '../Pages/BotHistory/BotHistoryReducers/BotHistoryReduces';

const appReducer = combineReducers({
    order: OrderReducers,
    kotHistory: kotHistoryReducer,
    botHistory : BotHistoryReducer,
    reservetable: ReserveReducer,
    login:LoginReducer,
    edit:EditReducers,
    editmenu:EditMenuReducers,
    editusers:EditUsersReducers,
    manageProduct:ManageReducers,
    // Assuming you have an order reducer
    // Add other reducers as needed
});
const rootReducer = (state, action) => {
    if (action.type === RESET_STATE) {
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer;