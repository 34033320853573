import React,{useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Navbar from "../../components/Navbar";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import RemoveIcon from '../../assets/Remove.png';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { CurrentCustomerOrder, OrderHistoryData, get_customer_order_details, kot, menudata } from './OrderActions/OrderActionApi';
//import { OrderActionType } from './OrderType/OrderActionType';
import ButtonBase from '@mui/material/ButtonBase';
import MinHeightTextarea from '../../components/MinHeightTextarea';
import {useNavigate} from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
//import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';


import './OrderIndex.css';
import {  TableData } from '../Reserve/ReserveActions/ReserveActionApi';
import { kothistory } from '../KotHistory/KotHistoryActions/KotHistoryActionApi';
import UserDetails from '../../components/UserDetails';
import { CategoryList } from '../EditMenu/EditMenuActions/EditMenuApi';
import { get_users_data } from '../EditUsers/EditUsersActions/EditUserActions';
import { socket_url } from '../../Redux/constants';
import { SelectedItemButtonColor, SendButtonColor, SubSelectButtonColor } from '../../components/GlobleColor';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const useStyles = makeStyles((theme) => ({
    searchBar: {
        borderRadius: 4,
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#5E5E5E', // Only setting border color, not adding a border
          },
          '&:hover fieldset': {
            borderColor: '#5E5E5E',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#5E5E5E',
          },
        },
        '& .MuiInputBase-input': {
          color: '#5E5E5E',
        },
        '& .MuiInputAdornment-root': {
          color: '#5E5E5E',
        },
      },
  }));

const OrderIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let counter = 0
    const token = sessionStorage?.getItem("Token");
    const email = sessionStorage?.getItem("Email");
    const product_id = sessionStorage?.getItem("ProductId");
    const tableno = sessionStorage?.getItem('tableno');
    const orderno = sessionStorage?.getItem('orderId');
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [timer, setTimer] = useState(null);
    const [popupData, setPopupData] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    const [selectedButton, setSelectedButton] = useState("All");
    const [selectedSubButton, setSelectedSubButton] = useState("ALL");
    const [selectedSubMenuItem, setSelectedSubMenuItem] = useState([]);
    const [textareaValue, setTextareaValue] = useState("");
    const [processedData,setprocessedData]=useState(null);
    const [Total,setTotal]=useState(0);
    const classes = useStyles();
    const [ws, setWs] = useState(null);

    const [data,setdata]=useState([]);
    const [Category,setCategory]=useState([]);
    const [CategoryType,setCategoryType]=useState('food');
    const [subdata,subsetdata]=useState([]);
    const [dataitem,setdataitem]=useState([]);

    const VegData = useSelector((state) => state.order.VegMenuResponseData);
    const NonVegData= useSelector((state)=> state.order.NonVegMenuResponseData);
    const MainData = useSelector((state)=> state.order.MainMenuResponseData);
    const ListCategory = useSelector((state) => state.editmenu.ListResponseData);

    const toTitleCase = (str) => {
        return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      };

    useEffect(() => {
        async function fetchData() {
        if(!token ||token === "undefined" || token === "null" || token === "none")
        {
            navigate("/")
        }
        else
        {
            await dispatch(menudata(product_id,email,token));
            await dispatch(CategoryList(email,product_id,token));
            if(VegData.length === 0 || NonVegData.length === 0  || MainData.length === 0 )
            {
                dispatch(get_users_data(email,product_id,token));
                dispatch(TableData(product_id, email, token));
                dispatch(OrderHistoryData(email, product_id, token));
                dispatch(kothistory(email, product_id, token));   
            }
        }
        const socket = new WebSocket(socket_url);
        setWs(socket);

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onmessage = async(event) => {
      try {
        const message = event.data;
      // Handle incoming messages and update state as needed
      if (message === "edit_menu") {
        await dispatch(CategoryList(email,product_id,token));
        await dispatch(menudata(product_id,email,token)); // Update KOT history on new message
      }
    }
    catch (error) {
      console.error("Error parsing message:", error);
    }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close(); // Clean up WebSocket connection on unmount
    }
    } 
    fetchData(); 
    },[dispatch]);

    
    useEffect(()=>{
        if(selectedButton === 'All')
        {
            setdata(MainData[CategoryType])
            setSelectedSubButton("ALL")
            const categories = [];
            for (const type in MainData[CategoryType]) {
                for (const cuisine in MainData[CategoryType][type]) {
                    if (Object.keys(MainData[CategoryType][type][cuisine]).length > 0) {
                        categories.push(cuisine);
                    }
                }
            }
            // Remove duplicates
            const uniqueCategories = [...new Set(categories)];
            setCategory(['ALL', ...uniqueCategories]);
        } 
        else if(selectedButton === 'veg')
        {
            setdata(VegData)
            setSelectedSubButton("ALL")
            setCategory(['ALL',...Object.keys(VegData)])
        }
        else if (selectedButton === 'Non Veg'){
            setdata(NonVegData)
            setSelectedSubButton("ALL")
            setCategory(['ALL',...Object.keys(NonVegData)]) 
        }
        

    },[selectedButton,VegData,NonVegData,MainData,CategoryType])

    useEffect(()=>{
        if (selectedButton && selectedSubButton && (data?.length!==0 && data !== undefined)) {
            if (selectedButton === 'All') {
               if(selectedSubButton === 'ALL')
                { 
                    subsetdata(data);
                    setprocessedData(
                        Object?.keys(data)?.flatMap((item, index) =>
                            Object?.keys(data[item])?.flatMap((item1)=>
                                Object?.keys(data[item][item1])?.map((item2, subIndex) => ({
                                id: item2, // Unique id using item, item1, index, and subIndex
                                quantity: 1,
                                name: data?.[item]?.[item1]?.[item2]?.["item_name"],
                                ingredients: data?.[item]?.[item1]?.[item2]?.["item_ingredients"]?.split(','),
                                description: data?.[item]?.[item1]?.[item2]?.["item_description"],
                                image: data?.[item]?.[item1]?.[item2]?.["item_image"],
                                prep_time: data?.[item]?.[item1]?.[item2]?.["item_prep_time_minutes"],
                                amount: data?.[item]?.[item1]?.[item2]?.["amount"],
                                type: item,
                            })))
                        )
                    );
            }
            else if( data?.length!==0 && data !== undefined) {
                    const item1 = selectedSubButton;
                    setprocessedData(
                        Object?.keys(data)?.flatMap((category, index) =>
                            Object?.keys(data[category])?.flatMap((cuisine, subIndex) =>
                                // Check if the current cuisine matches the selectedSubButton
                                cuisine === item1 ?
                                    Object.keys(data[category][cuisine])?.map((itemId) => ({
                                        id: itemId,
                                        quantity: 1,
                                        name: data[category][cuisine][itemId]?.["item_name"],
                                        ingredients: data[category][cuisine][itemId]?.["item_ingredients"]?.split(','),
                                        description: data[category][cuisine][itemId]?.["item_description"],
                                        image: data[category][cuisine][itemId]?.["item_image"],
                                        prep_time: data[category][cuisine][itemId]?.["item_prep_time_minutes"],
                                        amount: data[category][cuisine][itemId]?.["amount"],
                                        type: category,
                                    })) :
                                    [] 
                            )
                        )
                    );

                }
                return;
            }
        }
        if (selectedButton && selectedSubButton &&  (data?.length!==0 && data !== undefined)) {
            if (selectedSubButton === 'ALL') {
                subsetdata(data);
                setprocessedData(
                    Object?.keys(data)?.flatMap((item, index) =>
                      Object?.keys(data[item])?.map((item1, subIndex) => ({
                        id: item1, // Unique id using item, item1, index, and subIndex
                        quantity: 1,
                        name: data?.[item]?.[item1]?.["item_name"],
                        ingredients: data?.[item]?.[item1]?.["item_ingredients"]?.split(','),
                        description: data?.[item]?.[item1]?.["item_description"],
                        image: data?.[item]?.[item1]?.["item_image"],
                        prep_time: data?.[item]?.[item1]?.["item_prep_time_minutes"],
                        amount: data?.[item]?.[item1]?.["amount"],
                        type: selectedButton,
                      }))
                    )
                  );
            } else if( data?.length!==0 && data !== undefined) {
                subsetdata(data[selectedSubButton]);
                const subdata2 = data[selectedSubButton];
                setprocessedData(Object?.keys(subdata2)?.map((item1) => ({
                    id:item1,
                    name: subdata2?.[item1]?.["item_name"],
                    quantity: 1,
                    ingredients: subdata2?.[item1]?.["item_ingredients"]?.split(','),
                    description: subdata2?.[item1]?.["item_description"],
                    image: subdata2?.[item1]?.["item_image"],
                    prep_time: subdata2?.[item1]?.["item_prep_time_minutes"],
                    amount: subdata2?.[item1]?.["amount"],
                    type: selectedButton,
                })))
            }
        }

    },[selectedSubButton,data])
    

    useEffect(()=>{
        setdataitem(selectedSubMenuItem);
    },[selectedSubMenuItem])

    useEffect(()=>{
        var total=0;
        Object?.keys(dataitem)?.map((item)=>{
            total += (dataitem[item].amount * dataitem[item].quantity)
        })
        setTotal(total)
    },[dataitem])

    useEffect(()=>{
        console.log(selectedButton,selectedSubButton)
    },[selectedButton,selectedSubButton])

    const handlePopupClose = () => {
        setIsPopUpVisible(false);
      };
    const clearcart=()=>{
        setSelectedSubMenuItem([]);
    }
      const handlePopupReserveClose = () => {
        // Add your reserve close logic here
        setIsPopUpVisible(false);
      };
    const handleCategoryType = (categoryId)=>{
        setCategoryType(categoryId=== CategoryType ? categoryId:categoryId)
        setSelectedButton('All')
    }
    const handleButtonClick = (buttonId) => {
      setSelectedButton(buttonId === selectedButton ? buttonId : buttonId);
    };
    const handleButtonClickSub = (buttonId) => {
        setSelectedSubButton(buttonId === selectedSubButton ? buttonId : buttonId);
      };
    
      const handleItemClick = (item) => {
        if (!isPopUpVisible)
        {
        setSelectedSubMenuItem(prevState => {
            // Check if the item already exists in the array
            const index = prevState.findIndex(selectedItem => selectedItem.id === item.id);
            if (index !== -1) {
                // If item already exists, remove it
                const updatedState = [...prevState.slice(0, index), ...prevState.slice(index + 1)];
                return updatedState;
            } else {
                // If item does not exist, add it
                const updatedState = [...prevState, item];
                return updatedState;
            }
        });}
    };
    const handleItemClickWrapper = (item) => {
        if (!isPopUpVisible) {
            console.log("filter",item);
          handleItemClick(item);
        }
      };

    const handleRemoveItemClick = (itemId) => {
        setSelectedSubMenuItem(prevState => prevState.filter(item => item.id !== itemId));
    };

   /*  const processedData = selectedSubButton === "ALL" ?
        Object.keys(subdata)?.map((item) =>
            Object.keys(subdata[item])?.map((item1) => ({
                id:item1,
                quantity: 1,
                name: subdata?.[item]?.[item1]?.["item_name"],
                ingredients: subdata?.[item]?.[item1]?.["item_ingredients"]?.split(','),
                description: subdata?.[item]?.[item1]?.["item_description"],
                image: subdata?.[item]?.[item1]?.["item_image"],
                prep_time: subdata?.[item]?.[item1]?.["item_prep_time_minutes"],
                amount: subdata?.[item]?.[item1]?.["amount"],

            }))
        ).flat()
        :
        Object.keys(subdata)?.map((item1) => ({
            id:item1,
            name: subdata?.[item1]?.["item_name"],
            quantity: 1,
            ingredients: subdata?.[item1]?.["item_ingredients"]?.split(','),
            description: subdata?.[item1]?.["item_description"],
            image: subdata?.[item1]?.["item_image"],
            prep_time: subdata?.[item1]?.["item_prep_time_minutes"],
            amount: subdata?.[item1]?.["amount"],
        })); */

    const handleQuantity = (item,increment) => {
        // Clone the dataitem object to avoid mutating the state directly
        const newDataitem = { ...dataitem };
        // Update the quantity based on the increment value
        if (increment === 1) {
            newDataitem[item].quantity += 1;
        } else {
            if(newDataitem[item]["quantity"] === 1)
            {
                    handleRemoveItemClick(newDataitem[item]['id'])
            }
            else
            {
                newDataitem[item].quantity -= 1;
            } 
        }
    
        // Update the state with the new dataitem object
        setdataitem(newDataitem);
    };

    const sendkot = async ()  =>{
        const jsonData = Object.fromEntries(
            Object.values(dataitem).map((data) => [String(data.id), data.quantity])
        );
        const jsonString = JSON.stringify(jsonData);
        if (!jsonString || jsonString.length === 0 || jsonString === "{}") {
            console.log("jsonString is empty");
        } else {
            console.log("jsonString is not empty");
            try {
                await dispatch(kot(email,product_id, tableno, JSON.parse(jsonString), textareaValue,token));
                if(sessionStorage?.getItem("Role") === "customer"){
                    const order_id = await dispatch(CurrentCustomerOrder(product_id, email, tableno, token));
                    await dispatch(get_customer_order_details(product_id, email, order_id?.current_order_id, token));
                    navigate(`/customerOrderHistory`);
                }
                else
                {
                    dispatch(kothistory(email,product_id,token));
                    navigate(`/reserve`);
                }
            }
            catch (error) {
                console.error('Error completing KOT:', error);
              }
        }
        
    }
    const handleMouseDown = (item) => {
        const newTimer = setTimeout(() => {
            setIsPopUpVisible(true);
            console.log("popup :",item);
            setPopupData(item);
          // Open your pop-up here if needed
        }, 500); // 500ms for long press
        setTimer(newTimer);
      };

    const handleMouseUp = () => {
        clearTimeout(timer);
        setTimer(null);
        setTimeout(() => setIsPopUpVisible(false), 50);
      };

      const handleMouseLeave = () => {
        clearTimeout(timer);
        setTimer(null);
      };
    
      const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
      };
    
      const handleClearSearch = () => {
        setSearchValue('');
      };
    
      const filteredItems = searchValue
        ? processedData.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        : processedData;

    return (
        <>
            <Grid container style={{overflowX:"hidden",height:"100vh"}} className='maindata'>
            <Grid item xs={12} md={12} lg={2} sx={{zIndex:"9999"}}>
           <Navbar active="reserve"/>
            </Grid>
            <Grid item xs={12} md={12} lg={10}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} sx={{paddingRight:"1.5%",paddingLeft:"1.5%"}} className='userDetails'>
                        <UserDetails/>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={11.5} md={6} lg={7.6} className='menudata'>
                            <Paper elevation={3} sx={{height:"100%",borderRadius:2}}>
                                <Typography
                                    sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    color: '#5E5E5E',
                                    marginLeft: "1.5%",
                                    paddingTop: "1.5%"
                                    }}
                                    variant="h6"
                                    noWrap
                                    component="div"
                                >
                                Order Details For
                                </Typography>
                                <Grid container spacing={2} sx={{ marginLeft:"1.5%",marginTop:"0.5%", textAlign:"center"}}>
                                    <Grid item xs={5} sx={{marginBottom:"0.5%"}}>
                                        <Box sx={{ color: '#5E5E5E', p: 2 , border: '1px solid #5E5E5E', borderRadius: '5px'}}>
                                        Table No.  {tableno}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box sx={{ color: '#5E5E5E', p: 2 , border: '1px solid #5E5E5E', borderRadius: '5px' }}>
                                        Order No.  #{orderno?.toLowerCase() === "none"?"--":orderno}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Typography
                                    sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    color: '#5E5E5E',
                                    marginLeft: "1.5%",
                                    marginBottom:'0.1%',
                                    paddingTop: "1.5%"
                                    }}
                                    variant="h6"
                                    noWrap
                                    component="div"
                                >
                                Select Items
                                </Typography>
                                <Grid container spacing={2} className='selectItemsType' sx={{marginTop:'0.01%', marginLeft:"0.3%", textAlign: "center", marginBottom: "1.5%", overflowX: "auto", flexWrap:"nowrap"}}>
                                    <Grid item xs={3} md={3.8} lg={3.8}>
                                        <Button
                                        onClick={() => handleCategoryType('food')}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            color: CategoryType === 'food' ? '#fff' : '#000000',
                                            background: CategoryType === 'food' ? SelectedItemButtonColor : 'transparent',
                                            border: CategoryType === 'food' ? `1px solid ${SelectedItemButtonColor}` : '1px solid #000000',
                                           '&:hover': {
                                                background: CategoryType === 'food' ? SelectedItemButtonColor : SelectedItemButtonColor ,
                                                border: `1px solid ${SelectedItemButtonColor}`,
                                                color:"#ffff",
                                            },
                                        }}
                                        >
                                        Food
                                        </Button>
                                    </Grid>
                                    <Grid item xs={5} md={3.8} lg={3.8}>
                                        <Button
                                        onClick={() => handleCategoryType('beverage')}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            color: CategoryType === 'beverage' ? '#fff' : '#000000',
                                            background: CategoryType === 'beverage' ? SelectedItemButtonColor : 'transparent',
                                            border: CategoryType === 'beverage' ? `1px solid ${SelectedItemButtonColor}` : '1px solid #000000',
                                            '&:hover': {
                                                background: CategoryType === 'beverage' ? SelectedItemButtonColor : SelectedItemButtonColor ,
                                                border: `1px solid ${SelectedItemButtonColor}`,
                                                color:"#ffff",
                                            },
                                        }}
                                        >
                                        Beverages
                                        </Button>
                                    </Grid>
                                    <Grid item xs={5} md={3.8} lg={3.8}>
                                        <Button
                                        onClick={() => handleCategoryType('alcoholic_beverage')}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            color: CategoryType === 'alcoholic_beverage' ? '#fff' : '#000000',
                                            background: CategoryType === 'alcoholic_beverage' ? SelectedItemButtonColor : 'transparent',
                                            border: CategoryType === 'alcoholic_beverage' ? `1px solid ${SelectedItemButtonColor}` : '1px solid #000000',
                                            '&:hover': {
                                                background: CategoryType === 'alcoholic_beverage' ? SelectedItemButtonColor : SelectedItemButtonColor ,
                                                border: `1px solid ${SelectedItemButtonColor}`,
                                                color:"#ffff",
                                            },
                                        }}
                                        >
                                        Alcohols
                                        </Button>
                                    </Grid>
                                </Grid> 
                                <Grid container spacing={2} sx={{ display: CategoryType === 'food'? 'show':'none',marginLeft:"0.3%",textAlign: "center",marginBottom: "1.5%" }}>
                                    <Grid item xs={3} md={3.8} lg={3.8}>
                                        <Button
                                        onClick={() => handleButtonClick('All')}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            color: selectedButton === 'All' ? '#fff' : '#000000',
                                            background: selectedButton === 'All' ? SelectedItemButtonColor: 'transparent',
                                            border: selectedButton === 'All' ? `1px solid ${SelectedItemButtonColor}` : '1px solid #000000',
                                           '&:hover': {
                                                background: selectedButton === 'All' ? SelectedItemButtonColor : SelectedItemButtonColor,
                                                border: `1px solid ${SelectedItemButtonColor}`,
                                                color:"#ffff",
                                            },
                                        }}
                                        >
                                        All
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3} md={3.8} lg={3.8}>
                                        <Button
                                        onClick={() => handleButtonClick('veg')}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            color: selectedButton === 'veg' ? '#fff' : '#000000',
                                            background: selectedButton === 'veg' ? SelectedItemButtonColor : 'transparent',
                                            border: selectedButton === 'veg' ? `1px solid ${SelectedItemButtonColor}` : '1px solid #000000',
                                            '&:hover': {
                                                background: selectedButton === 'veg' ? SelectedItemButtonColor :SelectedItemButtonColor,
                                                border: `1px solid ${SelectedItemButtonColor}`,
                                                color:"#ffff",
                                            },
                                        }}
                                        >
                                        Veg
                                        </Button>
                                    </Grid>
                                    <Grid item xs={5} md={3.8} lg={3.8}>
                                        <Button
                                        onClick={() => handleButtonClick('Non Veg')}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            color: selectedButton === 'Non Veg' ? '#fff' : '#000000',
                                            background: selectedButton === 'Non Veg' ? SelectedItemButtonColor : 'transparent',
                                            border: selectedButton === 'Non Veg' ? `1px solid ${SelectedItemButtonColor}` : '1px solid #000000',
                                            '&:hover': {
                                                background: selectedButton === 'Non Veg' ? SelectedItemButtonColor: SelectedItemButtonColor,
                                                border: `1px solid ${SelectedItemButtonColor}`,
                                                color:"#ffff",
                                            },
                                        }}
                                        >
                                        Non Veg
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{textAlign: "center",overflowX:"auto",width:"94%",flexWrap:"nowrap",marginLeft:"2%"}}>
                                    {Category && Category.map((item) => ( 
                                        <Grid item xs={6} md={6} lg={6} key={item+1}>
                                            <Button
                                                onClick={() => handleButtonClickSub(item)}
                                                sx={{
                                                    width: '100%',
                                                    color: selectedSubButton === item ? '#fff' : '#A098AE',
                                                    background: selectedSubButton === item ? SubSelectButtonColor : 'transparent',
                                                    border: 'none',
                                                    '&:hover': {
                                                        background: selectedSubButton === item ? SubSelectButtonColor : SubSelectButtonColor,
                                                        border: 'none',
                                                        color: "#ffff",
                                                    },
                                                }}
                                            >
                                                {item.toUpperCase()}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Grid item xs={11} sx={{marginTop:"1%",marginLeft:"3%"}}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Search Item"
                                        value={searchValue}
                                        onChange={handleSearchChange}
                                        className={classes.searchBar}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                {searchValue ? (
                                                <IconButton onClick={handleClearSearch}>
                                                    <ClearIcon />
                                                </IconButton>
                                                ) : (
                                                <SearchIcon />
                                                )}
                                            </InputAdornment>
                                                ),
                                            }}
                                        />
                                </Grid>
                                <Container maxWidth="100%" height="auto" sx={{marginTop: '2.5%' }}>
                                    <Box sx={{ bgcolor: '#F5F5F5', height: '16rem', overflowY:'auto'}} className="menudata1">
                                        <Grid container spacing={1} sx={{ padding: '1.5%' }}>
                                            {filteredItems && filteredItems.map((item, index) => {
                                                counter += 1;
                                                return (
                                                <Grid item xs={6} md={4} lg={3} key={item.id + counter}>
                                                    <Box
                                                    onMouseDown={() => handleMouseDown(item)}
                                                    onMouseUp={handleMouseUp}
                                                    onMouseLeave={handleMouseLeave}
                                                    onTouchStart={() => handleMouseDown(item)}
                                                    onTouchEnd={handleMouseUp}
                                                    onTouchCancel={handleMouseLeave}
                                                    sx={{
                                                        background: selectedSubMenuItem.some(selectedItem => selectedItem.id === item.id) ? SubSelectButtonColor : '#D9D9D9',
                                                        color: selectedSubMenuItem.some(selectedItem => selectedItem.id === item.id) ? '#ffff' : '#7D7D7D',
                                                        height: '3rem',
                                                        textAlign: 'center',
                                                        '&:hover': {
                                                        background: selectedSubMenuItem.some(selectedItem => selectedItem.id === item.id) ? SubSelectButtonColor : 'rgba(217, 217, 217, 0.8)',
                                                        border: 'none',
                                                        color: selectedSubMenuItem.some(selectedItem => selectedItem.id === item.id) ? '#ffff' : '#7D7D7D',
                                                        cursor: 'pointer'
                                                        },
                                                    }}
                                                    onClick={() => handleItemClickWrapper(item)}
                                                    className='itemdata'
                                                    >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                        <Box sx={{ width: '90%' }}>
                                                        <Typography
                                                            sx={{
                                                            fontFamily: 'Poppins',
                                                            fontWeight: '800',
                                                            marginRight: '8px' // Add margin to space out the divider
                                                            }}
                                                            noWrap
                                                            component="div"
                                                            className='itemdata'
                                                        >
                                                            {toTitleCase(item?.name)}
                                                        </Typography>
                                                        </Box>
                                                        <Box sx={{ width: '10%', height: '100%', borderLeft: '4px solid', borderLeftColor: item?.type === 'veg'||CategoryType === 'beverage' ? '#058E6E' : '#8E0515' }} />
                                                    </Box>
                                                    </Box>
                                                </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Box>
                                </Container>
                            </Paper>
                            </Grid>
                            
                            <Grid item xs={11.5} md={5} lg={3.6} style={{height:"auto",paddingTop:"1.75%"}} className='cart'>
                                    <Paper elevation={3} sx={{height:'100%',borderRadius:2}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} md={6} lg={6}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Poppins',
                                                        fontWeight: '400',
                                                        color: '#5E5E5E',
                                                        paddingLeft:"5.5%"
                                                    }}
                                                    variant="h6"
                                                    noWrap
                                                    component="div"
                                                    className="carttext"
                                                >
                                                    Cart
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} sx={{display:"flex",justifyContent:"flex-end"}}>
                                                <img src={RemoveIcon} style={{width: "15%",paddingRight:"5.5%"}} className='im-delete' alt="Remove Icon" onClick={clearcart}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} sx={{ margin: "1.5%", textAlign: "center" }}>
                                            <Grid item xs={11} sx={{ marginBottom: "1.5%" }}>
                                                <Box sx={{ color: '#5E5E5E', p: 2, border: '1px solid #5E5E5E', borderRadius: '5px' }}>
                                                    Ordered Items
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} sx={{ margin: "1.5%"}}>
                                            <Grid item xs={12} sx={{ height: '20rem',overflowY:'auto'}} className='lgcart'>
                                                {dataitem && Object.keys(dataitem).map((item, index) => (
                                                    <>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <div key={index} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                                                <Box
                                                                    sx={{
                                                                        backgroundColor: '#0093FB',
                                                                        p: 1,
                                                                        border: '1px solid #0093FB',
                                                                        borderRadius: '50%',
                                                                        display: 'flex',
                                                                        marginRight: '1.5%',
                                                                        marginTop: '1%',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                    className="count"
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: '600',
                                                                            color: '#ffff',
                                                                        }}
                                                                        noWrap
                                                                        component="div"
                                                                    >
                                                                        {index + 1}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: '600',
                                                                        color: 'black',
                                                                    }}
                                                                    variant="h6"
                                                                    noWrap
                                                                    component="div"
                                                                >
                                                                    {toTitleCase(dataitem[item]["name"])}
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <Grid container spacing={1} sx={{marginTop:"1.5%"}}>
                                                                <Grid item xs={1.5}>
                                                                    <ButtonBase
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            borderRadius: '30%',
                                                                            backgroundColor: 'transparent',
                                                                            '&:hover': {
                                                                                backgroundColor: 'rgba(5, 142, 110, 0.2)',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleQuantity(item,0)}
                                                                        className='qm'
                                                                    >
                                                                        <IndeterminateCheckBoxIcon sx={{ color: '#058E6E'}} className='ab' />
                                                                    </ButtonBase>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: '700',
                                                                            color: '#000000',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                        }}
                                                                        
                                                                        noWrap
                                                                        component="div"
                                                                        className='quantity'
                                                                    >
                                                                        {dataitem[item]["quantity"]}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={1.5}>
                                                                    <ButtonBase
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            borderRadius: '30%',
                                                                            backgroundColor: 'transparent',
                                                                            '&:hover': {
                                                                                backgroundColor: 'rgba(5, 142, 110, 0.2)',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleQuantity(item,1)}
                                                                        className='qm'
                                                                    >
                                                                    <AddBoxIcon sx={{ color: '#058E6E'}} className='ab'/>
                                                                    </ButtonBase>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={5} sx={{marginTop:"1.5%"}}>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: '700',
                                                                    color: '#000000',
                                                                    display: 'flex',
                                                                    justifyContent:'flex-end'
                                                                    }}
                                                                noWrap
                                                                component="div"
                                                                className='amount'
                                                            >
                                                                Rs {dataitem[item]["amount"] * dataitem[item].quantity}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider sx={{marginBottom:"2.5%",width:"90%"}}/>
                                                    </>
                                                ))}
                                            </Grid>

                                            <Grid container spacing={1} sx={{width:"93%"}}>
                                                <Grid item xs={12} md={12} lg={12}>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <MinHeightTextarea value={textareaValue} setValue={setTextareaValue} className='note'/>
                                                        </Grid>
                                                    <Grid container spacing={1} sx={{marginBottom:"2.5%"}}>
                                                    <Grid item xs={6}>
                                                                <Typography sx={{
                                                                fontFamily: 'Poppins',
                                                                fontWeight: '600',
                                                                color: '#2E2E2E',
                                                                paddingTop: '5.75%',
                                                                paddingLeft: '2.5%',
                                                                fontSize: '22px',
                                                                }}
                                                                noWrap
                                                                component="div">
                                                                Total
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography sx={{
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: '800',
                                                                        color: '#2E2E2E',
                                                                        fontSize: '32px',
                                                                        paddingTop: '1.25%',
                                                                        textAlign:"center",
                                                                        }}
                                                                        noWrap
                                                                        component="div"
                                                                        className='total'
                                                                    >
                                                                        Rs {Total}
                                                                    </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{marginBottom:"5.5%"}}>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <Button sx={{
                                                                color: '#FFFFFF', p: 2, border: `1px solid ${SendButtonColor}`, borderRadius: '5px', background:SendButtonColor,width:"100%",
                                                                '&:hover': {
                                                                     backgroundColor:'#66B5FD'
                                                                },
                                                            }}
                                                            onClick={() => sendkot(textareaValue)}
                                                            className='sendkot'
                                                            >
                                                            {sessionStorage.getItem("Role") === "customer"?("Place Order"):("Send KOT")}    
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                <Dialog
                open={isPopUpVisible}
                onClose={handlePopupClose}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="md"
                PaperProps={{
                style: {
                    width: "650px", // Adjust the width as needed to make it square
                    height: "auto", // Adjust the height as needed to make it square
                    margin: "auto", // Center horizontally and vertically
                    borderRadius: '23px', // Remove border radius to make it square
                },
                }}
            >
                <DialogTitle>
                <div style={{ position: "relative" }}>
                    <IconButton
                    aria-label="close"
                    onClick={handlePopupClose}
                    style={{ position: "absolute", top: 0, right: 0 }}
                    >
                    <CloseIcon />
                    </IconButton>
                </div>
                </DialogTitle>

                {popupData && (
                <DialogContent className="dialog-content">
                    {popupData && (
                    <div>
                        <Box sx={{ fontSize: "60px", color: "#111010",fontFamily: "Poppins"}}>
                                <span className="label">{popupData.name}</span>
                        </Box>
                        <Box sx={{ fontSize: "25px", color: "#5E5E5E",fontFamily: "Poppins"}}>
                                <span className="label">{popupData.description}</span>
                        </Box>
                        <Box sx={{ minWidth: '100%'}}>
                        <Grid container spacing={1} sx={{marginTop:"0.5%"}}>
                            <Grid item xs={12} md={8} lg={8}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '30vh',
                                            backgroundImage: `url(${popupData.image})`,
                                            backgroundSize: 'cover', // Optional, to cover the entire area
                                            backgroundPosition: 'center', // Optional, to center the image
                                            borderTopRightRadius: '9px',
                                            borderBottomRightRadius: '9px',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{marginTop:"5%",marginBottom:"5%",display:'flex',flexWrap:"wrap"}}>
                                    <Grid item xs={8.7} md={8.7} lg={8.7}>
                                        <Typography 
                                            sx={{
                                                fontFamily: 'Poppins',
                                                fontWeight: 'bold',
                                                color: '#111010',
                                                fontSize: '18px',
                                                paddingTop:'2.5%',
                                                textAlign:'center'
                                                }}
                                                noWrap
                                                component="div">
                                                Preparation Time of The Dish :
                                        </Typography>  
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                fontSize: '2px',
                                                p:1,
                                                backgroundColor:"#0093FB",
                                                color:"#FFFFFF",
                                                borderRadius: '6px',
                                            }}
                                        >
                                            <Typography 
                                            sx={{
                                                fontFamily: 'Poppins',
                                                textAlign:"center",
                                                }}
                                                noWrap
                                                component="div">
                                                {popupData.prep_time} mins
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Box
                                    sx={{
                                        width:"100%",
                                        height: "38vh",
                                        backgroundColor: "#F2F2F2",
                                        borderRadius: '6px',
                                    }}
                                >
                                    <Box sx={{ fontSize: "20px", color: "#111010",fontFamily: "Poppins",height:"15%",paddingTop:"5px"}}>
                                        <span className="label2">Ingredients</span>
                                    </Box>
                                    <Divider sx={{width:"20%",backgroundColor: "#111010",height: "2px",marginLeft:"10%"}}/>
                                    <Grid container spacing={1} sx={{color:"#000000",marginTop:"5%"}}>
                                        {popupData?.ingredients?.map((item)=>(
                                            <Grid item xs={12} sx={{marginLeft:"10%",fontWeight:"550"}}>
                                                {item}
                                            </Grid>
                                        ))}
                                        
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        </Box>
                    </div>
                    )}
                </DialogContent>
                )}
            </Dialog>
        </Grid>
        </>
    );
}

export default OrderIndex;
