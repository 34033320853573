export const ManageTypes={
    MANAGE_LOADING_ADD_PRODUCT_DATA:'MANAGE_LOADING_ADD_PRODUCT_DATA',
    MANAGE_SUCCESS_ADD_PRODUCT_DATA:'MANAGE_SUCCESS_ADD_PRODUCT_DATA',

    GET_PRODUCT_LOADING_DATA:'GET_PRODUCT_LOADING_DATA',
    GET_PRODUCT_SUCCESS_DATA:'GET_PRODUCT_SUCCESS_DATA',

    GET_PRODUCT_LOADING_DATA_DETAILS:'GET_PRODUCT_LOADING_DATA_DETAILS',
    GET_PRODUCT_SUCCESS_DATA_DETAILS:'GET_PRODUCT_SUCCESS_DATA_DETAILS',

    EDIT_MANAGER_LOADING_DATA_MAIN:"EDIT_MANAGER_LOADING_DATA_MAIN",
    EDIT_MANAGER_SUCCESS_DATA_MAIN:"EDIT_MANAGER_SUCCESS_DATA_MAIN",
    EDIT_MANAGER_FAIL_DATA_MAIN:"EDIT_MANAGER_FAIL_DATA_MAIN",
    EDIT_MANAGER_CLEAR_DATA_MAIN:"EDIT_MANAGER_CLEAR_DATA_MAIN",

    EDIT_MANAGER_DETAILS_LOADING:"EDIT_MANAGER_DETAILS_LOADING",
    EDIT_MANAGER_DETAILS_SUCCESS:"EDIT_MANAGER_DETAILS_SUCCESS",

}