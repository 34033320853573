import { OrderActionType } from "../OrderType/OrderActionType";

const initialState = {
    MainMenuSuccessData: null,
    MainMenuLoadingData: null,
    MainMenuResponseData: [],
    MainMenuFailData: null,

    VegMenuSuccessData: null,
    VegMenuLoadingData: null,
    VegMenuResponseData: [],
    VegMenuFailData: null,

    NonVegMenuSuccessData: null,
    NonVegMenuLoadingData: null,
    NonVegMenuResponseData: [],
    NonVegMenuFailData: null,

   
    OrderHistoryLoading: null,
    OrderHistorySuccess: null,
    OrderHistoryResponse: [],
    OrderHistoryFail: null,

    OrderPaymentCompleteLoading: null,
    OrderPaymentCompleteSuccess: null,
    OrderPaymentCompleteResponse : [],
    OrderPaymentCompleteFail: null,

    OrderCompleteLoading: null,
    OrderCompleteSuccess: null,
    OrderCompleteResponse : [],
    OrderCompleteFail: null,
    
    OrderPrintLoading: null,
    OrderPrintSuccess: null,
    OrderPrintResponse : [],
    OrderPrintFail: null,

    CustomerOrderHistoryLoading: null,
    CustomerOrderHistorySuccess: null,
    CustomerOrderHistoryResponse: [],
    CustomerOrderHistoryFail: null,
    
};

export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case OrderActionType.MENU_LODING_DATA_MAIN:
            return {
                ...state,
                MainMenuLoadingData: true,
                MainMenuSuccessData: false,
                MainMenuResponseData: [],
                MainMenuFailData: false,
            };
        case OrderActionType.MENU_FAIL_DATA_MAIN:
            return {
                ...state,
                MainMenuLoadingData: false,
                MainMenuSuccessData: false,
                MainMenuResponseData: [],
                MainMenuFailData: true,
            };
        case OrderActionType.MENU_SUCCESS_DATA_MAIN:
            return {
                ...state,
                MainMenuLoadingData: false,
                MainMenuSuccessData: true,
                MainMenuResponseData: action.payload,
                MainMenuFailData: false,
            };
        case OrderActionType.MENU_CLEAR_DATA_MAIN:
            return {
                ...state,
                MainMenuLoadingData: null,
                MainMenuSuccessData: null,
                MainMenuResponseData: [],
                MainMenuFailData: null,
            };
        case OrderActionType.VEG_MENU_LOADING_DATA_MAIN:
            return {
                ...state,
                VegMenuLoadingData: true,
                VegMenuSuccessData: false,
                VegMenuResponseData: [],
                VegMenuFailData: false,
            };
        case OrderActionType.VEG_MENU_FAIL_DATA_MAIN:
            return {
                ...state,
                VegMenuLoadingData: false,
                VegMenuSuccessData: false,
                VegMenuResponseData: [],
                VegMenuFailData: true,
            };
        case OrderActionType.VEG_MENU_SUCCESS_DATA_MAIN:
            return {
                ...state,
                VegMenuLoadingData: false,
                VegMenuSuccessData: true,
                VegMenuResponseData: action.payload,
                VegMenuFailData: false,
            };
        case OrderActionType.VEG_MENU_CLEAR_DATA_MAIN:
            return {
                ...state,
                VegMenuLoadingData: null,
                VegMenuSuccessData: null,
                VegMenuResponseData: [],
                VegMenuFailData: null,
            };
        case OrderActionType.NON_VEG_MENU_LOADING_DATA_MAIN:
            return {
                ...state,
                NonVegMenuLoadingData: true,
                NonVegMenuSuccessData: false,
                NonVegMenuResponseData: [],
                NonVegMenuFailData: false,
            };
        case OrderActionType.NON_VEG_MENU_FAIL_DATA_MAIN:
            return {
                ...state,
                NonVegMenuLoadingData: false,
                NonVegMenuSuccessData: false,
                NonVegMenuResponseData: [],
                NonVegMenuFailData: true,
            };
        case OrderActionType.NON_VEG_MENU_SUCCESS_DATA_MAIN:
            return {
                ...state,
                NonVegMenuLoadingData: false,
                NonVegMenuSuccessData: true,
                NonVegMenuResponseData: action.payload,
                NonVegMenuFailData: false,
            };
        case OrderActionType.NON_VEG_MENU_CLEAR_DATA_MAIN:
            return {
                ...state,
                NonVegMenuLoadingData: null,
                NonVegMenuSuccessData: null,
                NonVegMenuResponseData: [],
                NonVegMenuFailData: null,
            };

            //---------- ORDER HISTORY -----------------

            case OrderActionType.ORDER_HISTORY_LOADING:
            return {
                ...state,
                OrderHistoryLoading: true,
                OrderHistorySuccess: false,
                OrderHistoryResponse: [],
                OrderHistoryFail: false,
            };
        case OrderActionType.ORDER_HISTORY_FAIL:
            return {
                ...state,
                OrderHistoryLoading: false,
                OrderHistorySuccess: false,
                OrderHistoryResponse: [],
                OrderHistoryFail: true,
            };
        case OrderActionType.ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                OrderHistoryLoading: false,
                OrderHistorySuccess: true,
                OrderHistoryResponse: action.payload,
                OrderHistoryFail: false,
            };
        case OrderActionType.ORDER_HISTORY_CLEAR_DATA:
            return {
                ...state,
                OrderHistoryLoading: null,
                OrderHistorySuccess: null,
                OrderHistoryResponse: [],
                OrderHistoryFail: null,
            };

        //-------ORDER PAYMENT COMPLETE-----------------

        case OrderActionType.ORDER_PAYMENT_COMPLETE_LOADING:
            return {
                ...state,
                OrderPaymentCompleteLoading: true,
                OrderPaymentCompleteSuccess: false,
                OrderPaymentCompleteResponse: [],
                OrderPaymentCompleteFail: false,
            };
        case OrderActionType.ORDER_PAYMENT_COMPLETE_FAIL:
            return {
                ...state,
                OrderPaymentCompleteLoading: false,
                OrderPaymentCompleteSuccess: false,
                OrderPaymentCompleteResponse: [],
                OrderPaymentCompleteFail: true,
            };
        case OrderActionType.ORDER_PAYMENT_COMPLETE_SUCCESS:
            return {
                ...state,
                OrderPaymentCompleteLoading: false,
                OrderPaymentCompleteSuccess: true,
                OrderPaymentCompleteResponse: action.payload,
                OrderPaymentCompleteFail: false,
            };
        case OrderActionType.ORDER_PAYMENT_COMPLETE_CLEAR_DATA:
            return {
                ...state,
                OrderPaymentCompleteLoading: null,
                OrderPaymentCompleteSuccess: null,
                OrderPaymentCompleteResponse: [],
                OrderPaymentCompleteFail: null,
            };

        //-------ORDER  COMPLETE-----------------

        case OrderActionType.ORDER_COMPLETE_LOADING:
            return {
                ...state,
                OrderPaymentCompleteLoading: true,
                OrderPaymentCompleteSuccess: false,
                OrderPaymentCompleteResponse: [],
                OrderPaymentCompleteFail: false,
            };
        case OrderActionType.ORDER_COMPLETE_FAIL:
            return {
                ...state,
                OrderCompleteLoading: false,
                OrderCompleteSuccess: false,
                OrderCompleteResponse: [],
                OrderCompleteFail: true,
            };
        case OrderActionType.ORDER_COMPLETE_SUCCESS:
            return {
                ...state,
                OrderCompleteLoading: false,
                OrderCompleteSuccess: true,
                OrderCompleteResponse: action.payload,
                OrderCompleteFail: false,
            };
        case OrderActionType.ORDER_COMPLETE_CLEAR_DATA:
            return {
                ...state,
                OrderCompleteLoading: null,
                OrderCompleteSuccess: null,
                OrderCompleteResponse: [],
                OrderCompleteFail: null,
            };


            // ------Order Print -------------

        case OrderActionType.ORDER_PRINT_LOADING:
            return {
                ...state,
                OrderPaymentPrintLoading: true,
                OrderPaymentPrintSuccess: false,
                OrderPaymentPrintResponse: [],
                OrderPaymentPrintFail: false,
            };
        case OrderActionType.ORDER_PRINT_FAIL:
            return {
                ...state,
                OrderPrintLoading: false,
                OrderPrintSuccess: false,
                OrderPrintResponse: [],
                OrderPrintFail: true,
            };
        case OrderActionType.ORDER_PRINT_SUCCESS:
            return {
                ...state,
                OrderPrintLoading: false,
                OrderPrintSuccess: true,
                OrderPrintResponse: action.payload,
                OrderPrintFail: false,
            };
        case OrderActionType.ORDER_PRINT_CLEAR_DATA:
            return {
                ...state,
                OrderPrintLoading: null,
                OrderPrintSuccess: null,
                OrderPrintResponse: [],
                OrderPrintFail: null,
            };
        
        case OrderActionType.ORDER_CUSTOMER_DETAILS_LOADING_DATA:
            return{
                ...state,
                CustomerOrderHistoryLoading: true,
                CustomerOrderHistorySuccess: false,
                CustomerOrderHistoryResponse: [],
                CustomerOrderHistoryFail: false,
            }
        case OrderActionType.ORDER_CUSTOMER_DETAILS_SUCCESS_DATA:
            return{
                ...state,
                CustomerOrderHistoryLoading: false,
                CustomerOrderHistorySuccess: true,
                CustomerOrderHistoryResponse: action.payload,
                CustomerOrderHistoryFail: false,
            }
        case OrderActionType.ORDER_CUSTOMER_DETAILS_FAIL_DATA:
            return{
                ...state,
                CustomerOrderHistoryLoading: false,
                CustomerOrderHistorySuccess: false,
                CustomerOrderHistoryResponse: [],
                CustomerOrderHistoryFail: true,
            }
        case OrderActionType.ORDER_CUSTOMER_DETAILS_CLEAR_DATA:
            return{
                ...state,
                CustomerOrderHistoryLoading: null,
                CustomerOrderHistorySuccess: null,
                CustomerOrderHistoryResponse: [],
                CustomerOrderHistoryFail: null,
            }


        default:
            return state;
    }
}
