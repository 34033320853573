export const OrderActionType={
    
    MENU_LODING_DATA_MAIN:"MENU_LODING_DATA_MAIN",
    MENU_SUCCESS_DATA_MAIN:"MENU_SUCCESS_DATA_MAIN",
    MENU_FAIL_DATA_MAIN:"MENU_FAIL_DATA_MAIN",
    MENU_CLEAR_DATA_MAIN:"MENU_CLEAR_DATA_MAIN",

    VEG_MENU_LODING_DATA_MAIN:"VEG_MENU_LODING_DATA_MAIN",
    VEG_MENU_SUCCESS_DATA_MAIN:"VEG_MENU_SUCCESS_DATA_MAIN",
    VEG_MENU_FAIL_DATA_MAIN:"VEG_MENU_FAIL_DATA_MAIN",
    VEG_MENU_CLEAR_DATA_MAIN:"VEG_MENU_CLEAR_DATA_MAIN",

    NON_VEG_MENU_LODING_DATA_MAIN:"NON_VEG_MENU_LODING_DATA_MAIN",
    NON_VEG_MENU_SUCCESS_DATA_MAIN:"NON_VEG_MENU_SUCCESS_DATA_MAIN",
    NON_VEG_MENU_FAIL_DATA_MAIN:"NON_VEG_MENU_FAIL_DATA_MAIN",
    NON_VEG_MENU_CLEAR_DATA_MAIN:"NON_VEG_MENU_CLEAR_DATA_MAIN",

    //-------------ORDER HISTORY----------------------

    ORDER_HISTORY_LOADING:"ORDER_HISTORY_LAODING",
    ORDER_HISTORY_SUCCESS:"ORDER_HISTORY_SUCCESS",
    ORDER_HISTORY_FAIL:"ORDER_HISTORY_FAIL",
    ORDER_HISTORY_CLEAR_DATA:"ORDER_HISTORY_CLEAR_DATA",

    // -------------- ORDER PAYMENT COMPLETE ---------------
    ORDER_PAYMENT_COMPLETE_LOADING:"ORDER_PAYMENT_COMPLETE_LOADING",
    ORDER_PAYMENT_COMPLETE_SUCCESS:"ORDER_PAYMENT_COMPLETE_SUCCESS",
    ORDER_PAYMENT_COMPLETE_FAIL:"ORDER_PAYMENT_COMPLETE_FAIL",
    ORDER_PAYMENT_COMPLETE_CLEAR_DATA:"ORDER_PAYMENT_COMPLETE_CLEAR_DATA",



    // -------------- ORDER PAYMENT COMPLETE ---------------
    ORDER_COMPLETE_LOADING:"ORDER_COMPLETE_LOADING",
    ORDER_COMPLETE_SUCCESS:"ORDER_COMPLETE_SUCCESS",
    ORDER_COMPLETE_FAIL:"ORDER_COMPLETE_FAIL",
    ORDER_COMPLETE_CLEAR_DATA:"ORDER_COMPLETE_CLEAR_DATA",

    // -------------- ORDER PAYMENT COMPLETE ---------------
    ORDER_PRINT_LOADING:"ORDER_COMPLETE_LOADING",
    ORDER_PRINT_SUCCESS:"ORDER_COMPLETE_SUCCESS",
    ORDER_PRINT_FAIL:"ORDER_COMPLETE_FAIL",
    ORDER_PRINT_CLEAR_DATA:"ORDER_COMPLETE_CLEAR_DATA",

    // ------------- CUSTOMER ORDER DETAILS -----------------

    ORDER_CUSTOMER_DETAILS_LOADING_DATA:'ORDER_CUSTOMER_DETAILS_LOADING_DATA',
    ORDER_CUSTOMER_DETAILS_SUCCESS_DATA:'ORDER_CUSTOMER_DETAILS_SUCCESS_DATA',
    ORDER_CUSTOMER_DETAILS_FAIL_DATA:'ORDER_CUSTOMER_DETAILS_FAIL_DATA',
    ORDER_CUSTOMER_DETAILS_CLEAR_DATA:'ORDER_CUSTOMER_DETAILS_CLEAR_DATA',


}